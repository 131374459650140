import styles from './Password.module.scss';
import React, {useEffect, useRef, useState, memo} from 'react';
import {Form} from "antd-mobile";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonPwdInput from "@/components/commonPwdInput/commonPwdInput";
import {useNavigate} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import accountApi from "@/service/accountApi";
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import { checkPassword} from "@/utils/utils";
import {useTranslation} from "react-i18next";
import {useDispatch } from "react-redux";
import useWalletSignHook from '@/utils/useWalletSignHook'

import md5 from "md5";

export default memo(function Password() {
  const [form] = Form.useForm()
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const {getWalletSign} = useWalletSignHook();

  const onFinish = (values) => {
    // 修改密码
    changePassword();
  }

  // 密码验证
  const passwordValidator = (_, value) => {
    if (!value || checkPassword(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("msg_incorrect_password")));
  };

  // 修改密码
  const changePassword = async () => {
    form.validateFields().then(values => {
      toastLoading();
      getWalletSign().then(res => {
        const pwd = md5(values.password);
        accountApi.changePassword({
          password: pwd,
          sign: res
        }).then(res => {
          if(res.status === 200) {
            toastSuccess(t('msg_change_password_success'));
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          }
        }).catch(err => {
          toastHide();
        })
      })
    })
  }

  useEffect(() => {
  }, []);

  return (
    <CommonPage title={t('lbl_modify_password')} flex className={styles.password}>
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <>
        <div className="h25"></div>
        <CommonButton block type='submit' color='primary'>{t('common.confirm')}</CommonButton>
        </>
      }>
        {/* 登录密码 */}
        <div className={styles.formItem}>
          <Form.Item name={'password'} rules={[{ required: true, message: t('msg_empty_password') }, { validator: passwordValidator }]}>
            <CommonPwdInput placeholder={t('lbl_placeholder_password')} iconLeft={null} />
          </Form.Item>
        </div>
        {/* 确认密码 */}
        <div className={styles.formItem}>
          <Form.Item name={'confirmPassword'} rules={[
            {
              required: true,
              message: t('msg_empty_confirm_password'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('msg_incorrect_confirm_password')));
              },
            }),
            { validator: passwordValidator }
          ]}>
            <CommonPwdInput placeholder={t('lbl_placeholder_confirm_password')} iconLeft={null} />
          </Form.Item>
        </div>
      </Form>
    </CommonPage>
  );
})
