/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-14 17:39:28
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useState, useRef } from 'react';
import styles from './Team.module.scss';
import { useTranslation } from "react-i18next";

import CommonPage from "components/commonPage/page/CommonPage";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {toastSuccess} from "utils/Toast";
import mineApi from '@/service/mineApi';
import moment from "moment";
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import {ReactComponent as IconCopy } from '@icon/Copy.svg';

export default memo(function Team() {
  const refList = useRef(null);
  const { t } = useTranslation();
  const [member, setMember] = useState([]); // 团队信息
  const [teamNum, setTeamNum] = useState([]); // 团队人数

  // 获取数据
  const getApiData = (page) => {
    return mineApi.getMyTeam({
      pageNo: page,
      pageSize: 20
    }).then(res => {
      setMember(res.data.member);
      setTeamNum(res.data.list.length);
      return res;
    })
  }

  const renderListItem = (item, index) => {
    return (
      <div className={styles.teamBox}>
        <div className={styles.teamItem}>
          <div className={styles.teamItemTop}>
            <div className={`text-16 ${styles.itemLabel}`}>{item.nickname}</div>
            <div className={`text-12 ${styles.itemValue}`}>{moment(item.bindTime).format('YYYY-MM-DD')}</div>
          </div>
          <CopyToClipboard text={item.bindAddress || ''} onCopy={() => toastSuccess(t('msg_copy_success'))}>
            <div className={`flex align-center text-12 ${styles.teamItemBottom}`}>
              <div>{item.bindAddress}</div>
              <IconCopy className={styles.btnCopy} />
            </div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  return (
    <CommonPage tt={t('lbl_battlefield')} flex title={t('lbl_my_team')} >
      <div className={styles.teamTop}>
        <div className={styles.avatarBox}>
          {
            member && member.teamImage && (
              <img className={styles.avatar} src={member.teamImage} alt='' />
            )
          }
        </div>
        <div className={styles.teamName}>{member.teamName}</div>
        <div className={styles.teamInfo}> 
          <span className={styles.label}>{t('lbl_team_consume')}</span>
          <span className={styles.value}>{member.totalMoney || 0.00} USDT</span>
        </div>
      </div>
      
      <div className={styles.teamTitle}>{t('lbl_my_team_num', {num: teamNum})}</div>
      <CommonPageScrollList
        ref={refList}
        renderListItem={renderListItem}
        getApiData={getApiData}>
      </CommonPageScrollList>
    </CommonPage>
  );
})  