import styles from './Index.module.scss';
import React, {useEffect, useRef, useState, memo, forwardRef} from 'react';
import {useNavigate} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonInput from "@/components/commonInput/CommonInput";
import CommonButton from 'components/commonButton/CommonButton';
import CommonModal from 'components/commonModal/CommonModal';
import {useTranslation, Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Loading, Modal} from 'antd-mobile';
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import accountApi from "@/service/accountApi";
import {setUser} from "@/redux/store/user";
import commonApi from 'service/commonApi';

export default memo(function AccountIndex() {
  // 默认头像
  const defaultAvatar = require('@/assets/images/default-avatar.jpg');
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {t} = useTranslation();

  // 用户信息
  const [userInfo, setUserInfo] = useState({});
  // 是否显示loading
  const [loading, setLoading] = useState(true);
  
  // 是否显示修改昵称弹窗
  const [isShowNickNameModal, setIsShowNickNameModal] = useState(false);
  const [form] = Form.useForm();

  // 取消设置昵称弹窗
  const handleCancel = () => {
    setIsShowNickNameModal(false);
    form.resetFields();
  }
  // 修改昵称
  const onFinish = (values) => {
    // 修改昵称
    changeNickName();
  }
  // 修改昵称
  const changeNickName = async () => {
    let data = form.getFieldsValue(true);
    let params = {
      nickname: data.nickname,
    }
    toastLoading();
    accountApi.updateNickname(params).then(res => {
      if (res.status === 200) {
        toastSuccess(t('msg_nickname_changed'));
        getUserInfo();
        handleCancel();
      }
    }).finally(() => {
      toastHide();
    });
  }
  // 修改昵称弹窗表单
  const FormNickName = forwardRef(() => {
    return (
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <div className={styles.btnGroup}>
          <CommonButton block cancel onClick={() => handleCancel()}>{t('common.cancel')}</CommonButton>
          <CommonButton style={{background: '#fff'}} block type='submit'>{t('common.confirm')}</CommonButton>
        </div>
      }>
        <Form.Item name="nickname" initialValue={userInfo.nickname} rules={[
          {
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(t('msg_incorrect_nickname'));
              } else {
                // 长度限制 中文1个算两个字符 英文1个算一个字符 2-14个字符
                if (value.replace(/[^\x00-\xff]/g, 'xx').length < 2 || value.replace(/[^\x00-\xff]/g, 'xx').length > 14) {
                  return Promise.reject(t('msg_incorrect_nickname'));
                } else {
                  return Promise.resolve();
                }
              }
            }
          }
          ]}>
          <CommonInput type={'text'} placeholder={t('lbl_placeholder_nickname_2_14')} />
        </Form.Item>
      </Form>
    )
  })

  // 获取用户信息
  const getUserInfo = () => {
    setLoading(true)
    accountApi.getUserInfo().then(res => {
      setUserInfo(res.data)
      dispatch(setUser(res.data));
    })
    .catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    });
  }

  // 上传头像
  const uploadAvatar = (e) => {
    toastLoading();
    commonApi.uploadImage({
      file: e.target.files[0]
    }).then(res => {
      if (res.status === 200) {
        let params = {
          avatar: res.data.Location
        }
        accountApi.updateAvatar(params).then(res => {
          if (res.status === 200) {
            getUserInfo();
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          toastHide();
        });
      }
    }).catch(() => {
      toastHide();
    })
  }
  
  useEffect(() => {
    // 获取用户数据
    getUserInfo();
  }, []);

  return (
    <CommonPage title={t('lbl_my_account')} flex padding0 className={styles.accountIndex}>
      <div className={styles.settingList}>
        {/* 修改昵称 */}
        <div className={styles.settingItem} onClick={() => setIsShowNickNameModal(true)}>
          <div>{t('lbl_modify_nickname')}</div>
          <div className={styles.settingItemRight}>
            <span>{userInfo.nickname}</span>
          </div>
        </div>
        {/* 修改头像 */}
        <div className={styles.settingItem}>
          <div>{t('lbl_modify_avatar')}</div>
          <div className={styles.settingItemRight}>
            {
              loading ? <Loading size='lg' /> : (
                <>
                  <img src={userInfo.avatar || defaultAvatar} alt="" className={styles.avatar} />
                </>
              )
            }
          </div>
          <input type="file" accept="image/*" className={styles.uploadAvatar} onChange={(e) => uploadAvatar(e)} />
        </div>
        {/* 修改密码 */}
        {
          userInfo.type === 1 && (
            <div className={styles.settingItem} onClick={() => navigate('password')}>
              <div>{t('lbl_modify_password')}</div>
              <div className={styles.settingItemRight}>
              </div>
            </div>
          )
        }
        {/* 账户管理 */}
        <div className={styles.settingItem} onClick={() => navigate('management')}>
          <div>{t('lbl_account_management')}</div>
          <div className={styles.settingItemRight}>
          </div>
        </div>
      </div>
      {/* 修改昵称弹窗 */}
      <CommonModal
        title={t('lbl_modify_nickname')}
        visible={isShowNickNameModal}
        onClose={handleCancel}
        content={<FormNickName/>}
      >
      </CommonModal>
    </CommonPage>
  );
})
