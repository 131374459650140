/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:18
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 11:07:43
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\redux\store\app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import commonApi from "../../service/commonApi";


const fetchCoinEthPrice = createAsyncThunk(
    'app/fetchCoinEthPrice',
    async () => {
        const response = await commonApi.getCoinPrice('ETH');
        // console.log(response)
        return response;
    });


export const appSlice = createSlice({
    name: 'app',
    //初始值不能为null， 必须是二级
    initialState: {
        sideCollapsed: true,
        leftMenuName: '首页',
        coinEthPrice: 0,
        coinBTCPrice: 0,
        heroLevel:[],
        showHomeStakePopup: false,
        walletCoinShow: {
            showBtc: false,
            // showEth: true,
            // showUsdt: true,
        },
        tempDate: {},
    },
    reducers: {
        setSideCollapsed: (state, action) => {
            state.sideCollapsed = action.payload
        },
        setLeftMenuName: (state, action) => {
            state.leftMenuName = action.payload;
        },
        setShowHomeStakePopup: (state, action) => {
            state.showHomeStakePopup = action.payload;
        },
        setWalletCoinShow: (state, action) => {
            state.walletCoinShow = action.payload;
        },
        setHeroLevel: (state, action) => {
            state.heroLevel = action.payload;
        },
        setTempDate:(state, action) => {
            state.tempDate = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoinEthPrice.fulfilled, (state, action) => {
                // console.log(action.payload);
                state.coinEthPrice = action.payload.coinPrice;
            })
    }
})

export const {
    setSideCollapsed,
    setLeftMenuName,
    setShowHomeStakePopup,
    setWalletCoinShow,
    setTempDate,
    setHeroLevel,
} = appSlice.actions;
export {fetchCoinEthPrice};

export default appSlice.reducer