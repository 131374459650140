import styles from './Management.module.scss';
import React, {useEffect, useState, memo} from 'react';
import CommonNavBar from "@/components/commonNavBar/CommonNavBar";
import {useNavigate} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import accountApi from "@/service/accountApi";
import { toastSuccess } from "@/utils/Toast";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import CommonDialog from 'components/commonDialog/CommonDialog';
import { copyToClipboard } from "@/utils/utils";
import useUserHooks from "@/hooks/useUserHooks";
import { setToken} from "@/redux/store/user";
import {ReactComponent as IconAdd } from '@icon/Add.svg';
import {ReactComponent as IconSwitch } from '@icon/Switch.svg';
import {ReactComponent as IconCopy } from '@icon/Copy.svg';

export default memo(function Management() {
  let { initUserData, userLogOut } = useUserHooks();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {t} = useTranslation();

  // ��������ָʾ��loading
  const [loading, setLoading] = useState(false);
  
  // ���˻��б�
  const [subAccountList, setSubAccountList] = useState([{
    nickname: 'nickname1',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 1, // 1:���˻� 2:���˻�
  }, {
    nickname: 'nickname2',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 2, // 1:���˻� 2:���˻�
  }, {
    nickname: 'nickname3',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 2, // 1:���˻� 2:���˻�
  }]);

  // ��ǰ�˻�
  const [currentAccount, setCurrentAccount] = useState({
    nickname: 'nickname',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
  });

  // �л�ʹ���˻�ΪNicknamename
  const switchText = (nickname) => {
    return (
      <div className={styles.switchText} style={{textAlign: 'center'}}>{t('lbl_switch_account_desc')}<br /><span>{nickname}</span></div>
    )
  }

  const header = () => {
    return (
      <div className={styles.header}>{t('lbl_switch_account')}</div>
    )
  }

  // �л����˻�
  const switchSubAccount = (item) => {
    CommonDialog({
      type: 'confirm',
      title: header(),
      //title: t('lbl_switch_account'),
      content: switchText(item.nickname),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        // ����л��������˻�
        if (item.type === 1) {
          // �˳���¼
          userLogOut();
          return;
        }
        else {
          // �л����˻�
          accountApi.subAccountLogin({
            nickname: item.nickname
          }).then(async (res) => {
            if (res.status === 200) {
              toastSuccess(t('msg_switch_account_success'));
              dispatch(setToken(res.data.token));
              await initUserData();
              getAccountList();
            }
          })
        }
      }
    })
  }

  // ��ȡ�˻��б�
  const getAccountList = () => {
    setLoading(true);
    accountApi.accountManagement({
      pageNo: 1,
      pageSize: 999
    }).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setSubAccountList(res.data.list);
        setCurrentAccount(res.data.member);
      }
    }).catch(err => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getAccountList();
  }, []);

  return (
    <CommonPage loading={loading} title={t('lbl_account_management')} flex className={styles.management} header={<CommonNavBar right={<IconAdd className={styles.btnAdd} onClick={() => navigate(`/account/management/create`, {state: {mainInviteCode: currentAccount.mainInviteCode}})} />}>{t('lbl_account_management')}</CommonNavBar>}>
      <div className={styles.main}>
        {/* ��ǰ�˻� */}
        <div className={styles.currentAccount}>
          <div className={styles.groupTitle}>{t('lbl_current_account')}</div>
          <div className={styles.curAccountItem}>
            <div className={styles.label}>{currentAccount.nickname}{currentAccount.type === 1 && ` (${t('lbl_main_account')})`}</div>
            <div className={styles.value}>{currentAccount.bindAddress}
              <IconCopy className={styles.iconCopy} onClick={() => {
                copyToClipboard(currentAccount.bindAddress);
                toastSuccess(t('msg_copy_success'));
              }} />
            </div>
          </div>
        </div>
        {/* ���˻��б�list */}
        <div className={styles.subAccount}>
          <div className={styles.groupTitle}>{t('lbl_account_list')}</div>
          {
            subAccountList.map((item, index) => {
              return (
                <div className={styles.subAccountItem}>
                  <div className={styles.label}>
                    <span>{item.nickname}{item.type === 1 && ` (${t('lbl_main_account')})`}</span>
                    <IconSwitch onClick={() => switchSubAccount(item)}/>
                  </div>
                  <div className={styles.value}>
                    {item.bindAddress}
                    <IconCopy className={styles.iconCopy} onClick={() => {
                      copyToClipboard(currentAccount.bindAddress);
                      toastSuccess(t('msg_copy_success'));
                    }} />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </CommonPage>
  );
})
