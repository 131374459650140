import React from 'react';
import {Button} from "antd-mobile";
import styles from './BlockButton.module.scss';
import PropTypes from "prop-types";

const BlockButton = (props) => {

    const {children, className, cancel, small, onClick, disabled,shadow, ...rest} = props;
    const buttonStyle = [
        styles.button,styles.custom_btn,styles.btn_11,
        cancel ? styles.cancel : styles.primary,
        small ? styles.buttonSmall : '',
        disabled ? styles.buttonDisabled : '',
        shadow?styles.buttonShadow:'',
        className].join(' ');
    const handleClick = () => {
        !disabled && onClick && onClick();
    }

    return (
        <Button className={buttonStyle} onClick={handleClick} {...rest}>
            {children}
        </Button>
    );
};

BlockButton.prototype = {
    cancel: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
};

BlockButton.defaultProps = {
    cancel: false,
    small: false,
    disabled: false,
    shadow: false
};

export default BlockButton;
