/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-21 17:36:52
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-12 15:14:46
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\icon\menu\IconHelp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const  Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M14.1965 19.6668H2.33876C1.92337 19.6668 1.5835 19.3278 1.5835 18.9135V7.08679C1.5835 6.67248 1.92337 6.3335 2.33876 6.3335H5.24654V7.84009H3.09403V18.1602H13.4412V7.84009H8.98511V6.3335H14.1587C14.5741 6.3335 14.914 6.67248 14.914 7.08679V18.9135C14.9517 19.3278 14.6119 19.6668 14.1965 19.6668Z" fill="white"/>
        <path d="M19.1635 14.6668H16.2633V13.1602H18.4102V2.84009H8.09009V13.1602H12.4969V14.6668H7.33679C6.92248 14.6668 6.5835 14.3278 6.5835 13.9135V2.08679C6.5835 1.67248 6.92248 1.3335 7.33679 1.3335H19.1635C19.5778 1.3335 19.9168 1.67248 19.9168 2.08679V13.9135C19.9168 14.3278 19.5778 14.6668 19.1635 14.6668Z" fill="white"/>
        </svg>
    );
};

export default Icon;
