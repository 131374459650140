/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-08 09:16:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-02 10:56:00
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\miningPool\Rule.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {memo, useEffect, useState, useRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import CommonNavBar from 'components/commonNavBar/CommonNavBar';

import CommonSelector from "components/commonSelector/CommonSelector";
import CommonPopup from 'components/commonPopup/CommonPopup';
import CommonInput from "components/commonInput/CommonInput";
import CommonButton from "components/commonButton/CommonButton";

import FilterItemLayout from "components/filterItemLayout/FilterItemLayout";

import { ReactComponent as IconFilter } from "assets/images/icon/Filter.svg";
import moment from 'moment'
import styles from './Histroy.module.scss';
import poolApi from "service/miningPoolApi";


export default memo(function MiningPool() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isApply, setIsApply] = useState(false);
  const [filterVisible,setFilterVisible] = useState(false);
  const [param, setParam] = useState({minearn:"",maxearn:"",order:[]});
  const changeParams = (value, key) => { setParam(prevState => {
    prevState[key] = value;
    return { ...prevState } }) }
  const refList = useRef(null);

  const sortType = [
    { label: t("lbl_sort_time_asc"), value: "1",filter:{orderBy:"id",orderType:"ASC"} },
    { label: t("lbl_sort_time_desc"), value: "2",filter:{orderBy:"id",orderType:"DESC"} },
    { label: t("lbl_sort_earn_asc"), value: "3",filter:{orderBy:"earn",orderType:"ASC"} },
    { label: t("lbl_sort_earn_desc"), value: "4",filter:{orderBy:"earn",orderType:"DESC"} },
  ];

  useEffect(() => {
  }, [])

  const getApiData = (page) => {
    const sortIteme = sortType.find(item => item.value == param?.order[0])||{}
    let params = { ...param, ...sortIteme.filter }
    return poolApi.history({
      pageNo: page,
      pageSize: 20,
      ...params
    });
  }
  const renderListItem = (item, index) => {
    return (
      <div className={styles.listItem}>
        <span className={styles.itemType}>{t("pool.income")}</span>
        <span className={styles.itemEarn}>{item.earn} {item.signToken}</span>
        <span className={styles.itemTime}>{ moment(item.earndTime).format('HH:mm:ss DD-MM-YYYY') }</span>
        {/*<div className={styles.listContent}>*/}
        {/*    <div className='flex justify-between align-center text-bold gap4'>*/}
        {/*      <span className='text-aliceBlue'>{t("pool.income")} {item.earn} {item.signToken}</span>*/}
        {/*      <span className='text-aliceBlue text-12'>{ moment(item.earndTime).format('HH:mm:ss DD-MM-YYYY') }</span>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    )
  }
  const search = async () => {
    refList?.current.refreshApiData();
    setFilterVisible(false);
  }
  const reset = async () => {
    setParam({minearn:"",maxearn:"",order:[]})
  }
  return (
    <CommonPage flex
      header={ <CommonNavBar right={<IconFilter  className={styles.filterBtn}  onClick={() => setFilterVisible(true)} />}>
            {t('pool.history')}
        </CommonNavBar> }>
      <CommonPageScrollList
        ref={refList}
        renderListItem={renderListItem}
        getApiData={getApiData}>
      </CommonPageScrollList>

      <CommonPopup visible={filterVisible} getVisible={setFilterVisible}
        footer={<div className={styles.action}>
          <CommonButton reset onClick={reset}>{t('common.reset')}</CommonButton>
          <CommonButton shadow onClick={search}>{t('common.confirm')}</CommonButton>
        </div>}>

        <FilterItemLayout title={t('pool.earn')}>
          <div className='flex align-center gap4'>
            <CommonInput type='text' placeholder={t("lbl_min")} poolApi  value={ param.minearn }  onChange={val => { changeParams(val, "minearn") }} />
            <div>-</div>
            <CommonInput type='text' placeholder={t("lbl_max")} value={ param.maxearn } onChange={val => { changeParams(val, "maxearn") }} />
          </div>
        </FilterItemLayout>

        <FilterItemLayout title={t('nft.sort')}>
          <CommonSelector columns={2} value={ param.order } options={sortType} onChange={val => { changeParams(val, "order") }}></CommonSelector>
        </FilterItemLayout>
      </CommonPopup>


    </CommonPage>
  );
})
