/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-08 09:16:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-27 15:35:24
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\miningPool\Rule.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {memo, useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import CommonPage from "components/commonPage/page/CommonPage";
import battlefieldApi from "service/battlefieldApi";
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import styles from './Rule.module.scss';


export default memo(function MiningPool() {
  const {t} = useTranslation();
  const [rule,setRule] = useState(null);


  useEffect(() => {
    getInfo()
  }, [])

  
  const getInfo = ()=>{ 
    toastLoading();
    battlefieldApi.rule().then(res => {
      setRule(res.data.content)
      toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  return (
    <CommonPage tt={'活动规则'} flex title={t('pool.rule')} >
        <div dangerouslySetInnerHTML={{__html: rule}} className={`${styles.content} ql-editor`}>
        </div>
    </CommonPage>
  );
})