import React, {useEffect, useState} from 'react';
import CommonPage from "../../components/commonPage/page/CommonPage";
import styles from './Styles.module.scss';

const Styles = () => {

    return (
        <CommonPage title={'全局样式'}>
            <div className={styles.content}>
                <div className='title-1 text-blue'>文字大小</div>

                <div className="title-1">title-1</div>
                <div className="title-2">title-2</div>
                <div className="title-3">title-3</div>
                <div className="body-1">body-1</div>
                <div className="body-2">body-2</div>
                <div className="caption-1">caption-1</div>

                <div className='title-1 text-blue'>颜色值</div>
                <div className="black">$colorBlack #111827</div>
                <div className="blue">$colorBlue #3784FB</div>
                <div className="white" style={{background: '#000'}}>$colorWhite #FFFFFF</div>
                <div className="aliceblue" style={{background: '#000'}}>$colorAliceBlue #9CA3AF</div>
                <div className="gray">$colorGray #9CA3AF</div>

            </div>
        </CommonPage>
    );
};

export default Styles;