/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-22 09:55:41
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-12 16:10:58
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\icon\IconLogo.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.0571 12.0047C14.0571 11.5892 14.1997 11.1849 14.46 10.8598L18.4922 5.83182C18.5523 5.75679 18.585 5.66363 18.585 5.5676V4.83481C18.585 4.72251 18.5403 4.61481 18.4607 4.53541C18.3811 4.456 18.2731 4.41139 18.1605 4.41139H6.84062C6.72803 4.41139 6.62006 4.456 6.54045 4.53541C6.46084 4.61481 6.41612 4.72251 6.41612 4.83481V5.56873C6.41612 5.66358 6.44895 5.7573 6.50894 5.83182L10.5422 10.8598C10.8026 11.1849 10.9452 11.5892 10.9452 12.0058V17.6864C10.9452 17.8467 11.0358 17.9935 11.1795 18.0646L14.0582 19.5009V12.0047H14.0571ZM6.84062 3.00113H18.1605C19.1759 3.00113 20 3.82312 20 4.83594V5.56986C20 5.98538 19.8574 6.3896 19.597 6.71478L15.5649 11.7427C15.5048 11.8177 15.4721 11.9109 15.472 12.0069V19.7301C15.472 19.9466 15.4165 20.1594 15.3107 20.3485C15.205 20.5376 15.0526 20.6965 14.868 20.8103C14.6834 20.9241 14.4726 20.989 14.2558 20.9987C14.039 21.0085 13.8233 20.9628 13.6292 20.866L10.5456 19.3281C10.2402 19.1757 9.98335 18.9415 9.80382 18.6518C9.62428 18.362 9.52916 18.0281 9.52909 17.6875V12.0069C9.52908 11.9109 9.49635 11.8177 9.43627 11.7427L5.40299 6.71365C5.14212 6.38834 5 5.98417 5 5.5676V4.83481C5 3.82199 5.82409 3 6.83948 3L6.84062 3.00113Z" fill="white"/>
        </svg>
    );
};

export default Icon;
