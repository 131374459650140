import React, {useEffect, useState} from 'react';
import CommonDropDown from "@/components/commonDropdown/CommonDropDown";
import styles from "./CoinDropDown.module.scss";
import {getCoinData} from "@/utils/constants";

const CoinDropDown = (props) => {

  const {title, coinData, itemClick} = props;

  const renderItem = (item, index) => {
    let coinInfo = getCoinData(item?.blockChain?.toLowerCase());
    return (
      <div className={styles.coinItem}>
        {/* {
          coinInfo.img &&
          <div className={styles.icon}>
            <img src={coinInfo.img} alt='' />
          </div>
        } */}
        <div className={styles.title}>
          {item.blockChain ? item.blockChain : coinInfo.title}
        </div>
      </div>
    )
  }

  // const itemClick = (item, index) => {
  //     console.log(item, index)
  // }

  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className="h10"></div>
      <CommonDropDown menuData={coinData} renderItem={renderItem} itemClick={itemClick}></CommonDropDown>
    </div>
  );
};

CoinDropDown.defaultProps = {
  title: '選擇主網'
}

export default CoinDropDown;