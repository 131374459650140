import React, {memo, useEffect, useState,useRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {useTranslation} from "react-i18next";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import IconIncome from "components/icon/miningPool/IconIncome";
import IconProduce from "components/icon/miningPool/IconProduce";
import CommonPopover from "components/commonPopover/CommonPopover";
import styles from './MiningPool.module.scss';
import classNames from 'classnames';
import { toastHide, toastLoading } from "utils/Toast";
import { tokenName } from '@/utils/walletConfig';

import IconApply from "components/icon/miningPool/IconApply";
import { ReactComponent as IconTips } from '@icon/tips.svg';
import { ReactComponent as IconClose } from '@icon/pool/CloseMusic.svg';
import { ReactComponent as IconOpen } from '@icon/pool/OpenMusic.svg';
import { ReactComponent as IconHistroy } from '@icon/pool/IconHistroy.svg';
import { ReactComponent as IconRule } from '@icon/pool/IconRule.svg';
import { ReactComponent as IconPowerFire } from '@icon/PowerFire.svg';
import { ReactComponent as IconPowerMoney } from '@icon/PowerMoney.svg';


import poolApi from "service/miningPoolApi";
import gap from "../../assets/images/coin/gap.png";


export default memo(function MiningPool() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [nftInfo, setNftInfo] = useState({});
  const [signInfo, setSignInfo] = useState({});
  const [signTime, setSignTime] = useState([]);
  const [isSign, setIsSign] = useState(false);
  const [isTime, setIsTime] = useState(false);
  const [signPower, setSignPower] = useState(0);

  const audioRef = useRef(null)
  const [playing, setPlaying] = useState(false);
  const mp3 = require("assets/MP3/11.mp3")
  useEffect(() => {
    getInfo()
  }, [])

  const toggleAudio =()=> {
    const audio = audioRef.current
    audio?.paused?audio.play():audio.pause()
    setPlaying(!audio.paused)
  }

  const getInfo = ()=>{
    toastLoading();
    poolApi.info().then(res => {
        setNftInfo(res.data.nft)
        setSignInfo(res.data.signInfo)
        setSignTime(res.data.signTime)
        setIsSign(res.data.isSign)
        setIsTime(res.data.inTime)
        setSignPower(res.data.signPower)
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  const sign = ()=>{
    toastLoading();
    poolApi.sign().then(res => {
        console.log(res)
        getInfo()
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  return (
    <CommonPage tt={t('lbl_mining_pool')} flex padding0 title={t('lbl_mining_pool')}
    header={<CommonNavBar back={null} className="bgImg">{t('lbl_mining_pool')}</CommonNavBar>}>
      <div className={ styles.mainContent }>
        <audio ref={audioRef}  loop="loop" preload="auto" style={{display:"none"}} src={mp3}/>
        <span className={styles.music} onClick={toggleAudio}>
          {playing?<IconOpen className={styles.icon}/>:<IconClose className={styles.icon}/>}
        </span>

          <div className={ styles.headerContent }>
              <div className={classNames('flex align-center flex-sub justify-center text-36',styles.headerItem)} >
                  <IconPowerFire width={36} height={36} />
                  <div>
                      <CommonPopover content={t("pool.tips", {tokenName: tokenName})}>
                          <div className={styles.headerItemTitle}>
                              <span>{t("pool.myProduce")}</span>
                              <IconTips className={styles.tipsIcon} />
                          </div>
                      </CommonPopover>
                      <div className={styles.headerItemNum}>{signPower} W</div>
                  </div>
              </div>
              <div className={classNames('',styles.headerItem)}>
                  <IconPowerMoney width={36} height={36} />
                  <div>
                      <div className={styles.headerItemTitle}>{t("pool.totalIncome")}</div>
                      <div className={styles.headerItemNum}>{nftInfo?.signEarns||0} {tokenName}</div>
                  </div>
              </div>
          </div>
      </div>

      <div className={ styles.footerContent }>
        <div className={ styles.footerImg}>
            <img className={styles.bomCatImg} src={nftInfo?.image || require('@/assets/images/1.jpg')} alt=""/>
            <div className={styles.bomCatInfo}>
                <img src={gap} />
                <div className={styles.bomCatCoin}>{nftInfo?.price||0}</div>
            </div>
        </div>

        <div className={styles.footerRight}>
          <div className={styles.footerRightTop}>
              <button className={styles.btnTop} onClick={()=>navigate('histroy')}>
                  <IconPowerMoney width={16} height={16} />
                  <span>{t("pool.history")}</span></button>
              <button className={styles.btnTop} onClick={()=>navigate('rule')}>
                  <IconRule className={ styles.icon }/>
                  <span>{t("pool.rule")}</span></button>
          </div>
            { isSign?
                <button className={classNames(styles.apply_btn, styles.active)} >
                    <IconApply width={16} height={16} />
                    <span>{t("pool.activating")}</span>
                </button>
                :
                isTime?
                    <button className={styles.apply_btn} onClick={sign}>
                        <IconApply className={classNames.icon}/>
                        <span>{t("pool.active")}</span>
                    </button>:
                    <CommonPopover content={<div className={styles.singleLineContent}>
                        <div>{t("pool.activationTime")}</div>
                        {
                            signTime.length === 1 ? <div>{t("pool.allDay")} {signTime[0]}</div>:
                                <div>
                                    <div>{t("pool.day")} {signTime[0]}</div>
                                    <div>{t("pool.night")} {signTime[1]}</div>
                                </div>
                        }
                    </div>}>
                        <button className={styles.apply_btn} >
                            <IconApply className={classNames.icon}/>
                            <span>{t("pool.waiting")}</span>
                        </button>
                    </CommonPopover>
            }
        </div>
      </div>
      {/*<div style={{ height: "4rem" }}></div>*/}
    </CommonPage>
  );
})
