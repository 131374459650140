import Page from "./Page";
import MiningPool from "../pages/miningPool/MiningPool";
import Histroy from "../pages/miningPool/Histroy";
import Rule from "../pages/miningPool/Rule";
import i18next from '../i18n/index.js'

const miningPoolRoute = [
  Page("产能池", 'miningPool', null,[
      // Page('产能池', '', <MiningPool/>),
      Page('活动规则', 'rule', <Rule/>),
      Page('收益明细', 'histroy', <Histroy/>),
  ])

 
]
export default miningPoolRoute;