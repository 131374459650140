import React, {useEffect, useState} from 'react';
import CommonPage from "../../components/commonPage/page/CommonPage";
import styles from './rule.module.scss';
import {useTranslation} from "react-i18next";

const ServiceRule = () => {
    let {t} = useTranslation();

    const tips = `
    <p>歡迎使用 Crydit 信用卡虛擬卡發行服務。請在使用本服務之前仔細閱讀以下條款與細則。</p>
  
  <h2>1. 服務接受條款</h2>
  <p>本條款與細則規範您使用 Crydit 提供的信用卡虛擬卡發行服務。當您使用本服務時，即表示您同意遵守以下條款與細則。</p>
  
  <h2>2. 使用許可</h2>
  <p>您獲得使用 Crydit 提供的信用卡虛擬卡發行服務的有限許可。您不得轉售、轉讓或以任何形式進行商業利用。</p>
  
  <h2>3. 虛擬卡使用</h2>
  <p>您使用 Crydit 提供的虛擬卡時，請遵守相關法律法規和條例。您需對虛擬卡的使用負責，包括但不限於合法消費和遵守消費限制。</p>
  
  <p class="highlight">請注意：虛擬卡只能用於網上消費，不可用於線下實體店鋪。</p>
  
  <h2>4. 責任限制</h2>
  <p>在使用 Crydit 服務時，我們將盡力保護您的個人信息和資金安全。然而，我們無法對由於不可抗力或第三方因素導致的損失負責。</p>
  
  <h2>5. 協議修改</h2>
  <p>我們保留隨時修改本條款與細則的權利。修改後的協議將在網站上公布，並立即生效。請定期查看以獲取最新版本的服務條款。</p>
  
  <p>如有任何疑問或爭議，請聯繫我們的客服團隊。</p>
        `

    return (
        <CommonPage title={'Crydit 服務條款'}>
            <div dangerouslySetInnerHTML={{__html: tips}} className={`${styles.rule}`}>
            </div>
        </CommonPage>
    );
};

export default ServiceRule;