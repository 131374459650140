import React, {useEffect, useState} from 'react';
import styles from './CommonDropDown.module.scss';
import {DownOutline, UpOutline} from "antd-mobile-icons";
import PropTypes from "prop-types";
import CommonPopup from "../commonPopup/CommonPopup";

const DefaultDropDownItem = (props) => {

  const {title, ...rest} = props;

  return (
    <div className={styles.item}>
      <div className={styles.title}>
        {title}
      </div>
    </div>
  );
}

const CommonDropDown = (props) => {
  const {menuData, selectedIndex = 0} = props;
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(menuData.length > 0 ? menuData[selectedIndex] : {});

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      setSelectedItem(menuData[0]);
    }
  }, [menuData]);

  return (
    <div>
      <div className={styles.content} onClick={() => setVisible(true)}>
        <div className={styles.itemContent}>
          {
            props.renderItem ? props.renderItem(selectedItem, 0) :
              <DefaultDropDownItem {...selectedItem}></DefaultDropDownItem>
          }
        </div>
        <div className={styles.arrow}>
          {
            visible ? <UpOutline/> : <DownOutline/>
          }
        </div>
      </div>


      <CommonPopup visible={visible} getVisible={() => {
        setVisible(false);
      }} title={''}>
        {
          menuData.map((item, index) => {
            return (
              <div key={index} onClick={() => {
                setVisible(false);
                props.itemClick && props.itemClick(item, index);
                setSelectedItem(item);
              }
              }>
                {
                  props.renderItem ? props.renderItem(item, index) :
                    <DefaultDropDownItem {...item}></DefaultDropDownItem>
                }
                {index < (menuData.length - 1) && (
                  <>
                    <div className="h05"></div>
                    <div className={styles.itemBorderBottom}></div>
                    <div className="h05"></div>
                  </>
                )}
              </div>
            )
          })
        }
      </CommonPopup>
    </div>
  );
};

CommonDropDown.prototype = {
  menuData: PropTypes.isPrototypeOf(Array).required,
  selectedMenu: PropTypes.isPrototypeOf(Object),
  renderItem: PropTypes.isPrototypeOf(Function),
  itemClick: PropTypes.isPrototypeOf(Function),
}

CommonDropDown.defaultProps = {
  menuData: [
    {
      title: 'Title1',
    }, {
      title: 'Title2',
    }, {
      title: 'Title3',
    }, {
      title: 'Title4',
    },
  ],
}

export default CommonDropDown;