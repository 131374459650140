import React, {useEffect, useState} from 'react';
import {useAccount, useAccountEffect, useDisconnect, useSignMessage} from "wagmi";
import {setAddress} from "../redux/store/user";
import {useDispatch, useSelector} from "react-redux";
import useUserHooks from "./useUserHooks";
import {watchAccount} from '@wagmi/core'
import wagmiConfig from "../utils/walletConfig";
import {useDebouncedCallback} from 'use-debounce';

const UseWalletHook = () => {
    const userInfo = useSelector(state => state.user.user);
    const userToken = useSelector(state => state.user.token);
    const {userLogOut} = useUserHooks();
    // const {disconnect} = useDisconnect();

    //全局监控小狐狸连接状态.
    const {isConnected, address} = useAccount();

    useAccountEffect({
        onConnect(data) {
            console.log('Connected!', data)
        }, onDisconnect() {
            console.log('Disconnected!')
        },
    })


    useEffect(() => {
        console.log('init watch account')
        const unwatch = watchAccount(wagmiConfig, {
            onChange(account) {
                console.log('Account changed!', account)
                if (account.status = "connected") {
                    debounced();
                }
            },
        })

        return () => {
            console.log('unwatch')
            unwatch();
        }
    }, [])


    const debounced = useDebouncedCallback(
        // function
        (value) => {
            //检查用户是否登录 。 登录的是否相同
            if (userToken && userInfo != null) {
                if (address?.toLowerCase() !== userInfo.bindAddress?.toLowerCase()) {
                    console.log('user change wallet ')
                    userLogOut();
                }
            }
        },
        // delay in ms
        300
    );
    // useEffect(() => {
    //     console.log('address change: ', address);
    //     if (userToken) {
    //         console.log(address?.toLowerCase())
    //         console.log(JSON.stringify(userAddress)?.toLowerCase())
    //         //是否是用户切换钱包。切换的话需要退出重新登录
    //         if (address?.toLowerCase() !== userAddress?.toLowerCase()) {
    //             console.log('user change wallet ')
    //             debounced();
    //         }
    //     }
    // }, [address]);

    return {isConnected};
};

export default UseWalletHook;