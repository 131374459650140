/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-12 09:48:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-13 15:38:00
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\router\nft.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Page from "./Page";
import NFT from "../pages/nft/Nft";
import Shop from "../pages/nft/Shop";
import Hero from "../pages/nft/Hero";
import Material from "../pages/nft/Material";
import BuyHero from "../pages/nft/BuyHero";
import BuyMaterial from "../pages/nft/BuyMaterial";
import Team from "../pages/nft/Team";
import i18next from '../i18n/index.js'

const miningPoolRoute = [
  Page("NFT模块", 'nft', null,[
      Page('NFT列表', '', <NFT/>),
      Page('英雄NFT', 'hero', <Hero/>),
      Page('素材NFT', 'material', <Material/>),
  ]),

  Page("NFT市场", 'shop', null,[
    Page('NFT列表', '', <Shop/>),
    Page('英雄NFT', 'hero', <Hero/>),
    Page('素材NFT', 'material', <Material/>),
    Page('购买英雄NFT', 'hero/buy', <BuyHero/>),
    Page('购买素材NFT', 'material/buy', <BuyMaterial/>),
  ]),

  Page("我的团队", 'team', null,[
    Page('团队列表', '', <Team/>),
  ])
]
export default miningPoolRoute;