import React, {memo, useEffect, useState } from 'react';
import styles from './RegisterSuccess.module.scss'
import {useNavigate} from "react-router-dom";
import CommonPageTips from "../../../components/commonPageTips/CommonPageTips";
import {toastHide, toastLoading} from "../../../utils/Toast";
import { setToken} from "../../../redux/store/user";
import {useAccount, useSignMessage} from "wagmi";
import {useDispatch} from "react-redux";
import accountApi from "../../../service/accountApi";
import {useTranslation} from "react-i18next";
import useUserHooks from "../../../hooks/useUserHooks";
import CommonNavBar from "@/components/commonNavBar/CommonNavBar";
import CommonPage from "@/components/commonPage/page/CommonPage";

export default memo(function RegisterSuccess() {

  let navigate = useNavigate();
  let {t} = useTranslation();
  let {initUserData} = useUserHooks();
  const {signMessageAsync} = useSignMessage();
  const {address, isConnected} = useAccount()
  let dispatch = useDispatch();

  const enterHome = () => {
    toastLoading();
    accountApi.getNonce(address)
      .then(res => {
        return signMessageAsync({message: res.data.nonce})
      })
      .then(res => {
        return accountApi.login({
          'identify': address,
          'secret': res,
          type: 1
        })
      })
      .then(res => {
        console.log("res>>>>>>>>>>>>", res)
        dispatch(setToken(res.data.token));
        initUserData();
        setTimeout(() => {
          toastHide();
          navigate('/home');
        }, 30)
      })
      .catch(err => {
        toastHide();
        // toastError(err);
        console.log(err)
      });
  }

  return (
    <CommonPage title={t('lbl_register_success')} flex className={styles.page} header={<CommonNavBar back={null}>{t('lbl_register_success')}</CommonNavBar>}>
      <CommonPageTips type={'default'} title={t('msg_register_success')} tips={t('msg_register_success_desc')}
        textConfirm={t('common.complete')} textCancel={null}
        onConfirm={() => {
          enterHome();
        }}
        onCancel={() => {
          enterHome();
        }}
      >
      </CommonPageTips>
    </CommonPage>
  );
});