import React, {useEffect, useState} from 'react';
import styles from './Styles.module.scss';

const Box = (props) => {

    const {title, children} = props;

    return (
        <div style={{padding: '10px 0'}}>
            <div className='title-2'>
                {title}
            </div>
            <div style={{marginTop: '10px'}} className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default Box;