import React from 'react';
import PropTypes from "prop-types";
import {ErrorBlock, SpinLoading} from "antd-mobile";
import styles from './ResultPage.module.scss';

const ResultPage = (props) => {
    const {error, loading, onClick, extraClassName} = props;

    if (error) {
        return (
            <div className={[styles.content, extraClassName].join(' ')} onClick={() => {
                onClick && onClick();
            }}>
                <ErrorBlock status='disconnected'/>
            </div>
        )
    }

    if (loading) {
        return (
            <div className={[styles.content, extraClassName].join(' ')}>
                <SpinLoading color='primary' style={{'--size': '48px'}}/>
            </div>
        )
    }

    return (
        <div className={[styles.content, extraClassName].join(' ')} onClick={() => {
            onClick && onClick();
        }}>
            <ErrorBlock status='empty'/>
        </div>
    )

};

ResultPage.prototype = {}

ResultPage.defaultProps = {}

export default ResultPage;