/* 公用API */
import { post, postFile } from "./request";

const commonApi = {
  // 提现接口
  withdraw: (data) => (post(`/api/portal/wallet/create`, data)),
  // 获取主网列表
  getChainNet: () => (post('/api/portal/wallet/getBlockChain')),
  // 根据主网ID获取代币列表
  getChainCoin: (data) => (post('/api/portal/wallet/getBlockCoin', data)),
  // 获取充值地址
  rechargeAddress: (data) => (post('/api/portal/wallet/getAddress', data)),
  // 上传图片
  uploadImage: (data) => (postFile('/api/portal/aws/upload', data)),
};

export default commonApi;