/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 14:07:18
 * @FilePath: \weapp\wepay-h5\src\pages\home\Home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {memo, useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {Swiper, SpinLoading} from "antd-mobile";
import styles from './Home.module.scss';
import {useTranslation} from "react-i18next";
import HomeNavBar from "@/components/homeNavBar/HomeNavBar";
import HomeContent from "@/layouts/HomeContent/HomeContent";
import CommonButton from "@/components/commonButton/CommonButton";
import BlockButton from "@/components/commonButton/BlockButton";
import UpgradeButton from "@/components/commonButton/UpgradeButton";
import homeApi from '@/service/homeApi';
import CommonPopover from "@/components/commonPopover/CommonPopover";
import { tokenName } from '@/utils/walletConfig';

// import { ReactComponent as IconMiningValue } from '@icon/mining_value.svg';
// import { ReactComponent as IconEarnings } from '@icon/earnings.svg';
import { ReactComponent as IconInfo } from '@icon/info.svg';
// import { ReactComponent as IconRank } from '@icon/rank.svg';
import { ReactComponent as IconPowerFire } from '@icon/PowerFire.svg';
import { ReactComponent as IconPowerMoney } from '@icon/PowerMoney.svg';
import { ReactComponent as IconGroupRank } from '@icon/GroupRank.svg';
// import { ReactComponent as IconPowerFaq } from '@icon/PowerFaq.svg';
import ReactECharts from 'echarts-for-react';
import moment from "moment";

export default memo(function Home() {
  let {t} = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();

  // 轮播图
  const [imgBanner, setImgBanner] = useState([]);
  // GAP价格
  const [gapPrice, setGapPrice] = useState(0);
  // 我的产能值
  const [myPower, setMyPower] = useState(0);
  // 累计收益
  const [totalIncome, setTotalIncome] = useState(0);
  // 团战排行榜
  const [rankList, setRankList] = useState([]);
  // NFT升级购买
  const [nftUpgrade, setNftUpgrade] = useState([]);
  // 加载中
  const [loading, setLoading] = useState(true);
  const [carouselTime, setCarouselTime] = useState(3000);
  // k线图
  const [xAxisData, setXAxisData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  // GAP图标
  const CoinGAP = (props) => {
    return (
      <img src={require('@/assets/images/coin/gap.png')} width={props.width} height={props.height} alt=""/>
    )
  }

  // 获得首页数据
  const getHomeData = async () => {
    setLoading(true);
    let res = await homeApi.getHomeData();
    setLoading(false);
    const { price, nfts, ranking, images,carouselImageTime } = res.data;
    // 轮播图
    setImgBanner(images);
    // GAP价格
    setGapPrice(price);
    // 我的产能值
    setMyPower(nfts.signPower);
    // 累计收益
    setTotalIncome(nfts.totalEarns);
    // NFT升级购买
    setNftUpgrade(nfts.upgradeLogs);
    // 排行榜
    setRankList(ranking);
    // 轮播图时间
    setCarouselTime(carouselImageTime*1000)

    // k线图数据
    let klRes = await homeApi.getKlineData();
    // console.log('k线图数据=====>', klRes);
    let lineValues = [], lineTimes = [];
    for (let i = 0; i < klRes.data.length; i++) {
      const item = klRes.data[i];
      lineValues.push(item.lineValue);
      lineTimes.push(moment(item.lineTime).format('MM/DD'));
    }
    setXAxisData(lineTimes);
    setSeriesData(lineValues);
  }

  const getOption = () => {
    return  {
      title: {
        show: false
      },
      legend: {
        show: false
      },
      grid: {
        // show: true,
        top: 18,
        bottom: 30,
        left: 36,
        right: 18,
      },
      xAxis: {
        type: 'category',
        nameGap: 0,
        axisLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: '#EDFFF6'
          }
        },
        axisTick: {
          show: true,
          length: 3,
          alignWithLabel: true,
        },
        axisLabel: {
          fontSize: 10,
          color: '#EDFFF6',
          margin: 5,
        },
        boundaryGap: true,
        // data: ['06-30', '07-01', '07-02', '07-03', '07-04', '07-06', '07-07']
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        nameGap: 0,
        axisLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: '#EDFFF6'
          }
        },
        axisTick: {
          show: true,
          length: 3,
          alignWithLabel: true,
        },
        splitNumber: 3,
        alignTicks: true,
        axisLabel: {
          fontSize: 10,
          color: '#EDFFF6',
          margin: 6,
        },
        boundaryGap: true,
        scale: true,
        min: function (value) {
          return value.min - (value.min * 0.05);
        },
        splitLine: {
          show: false
        },
      },
      series: [
        {
          // data: [0.734, 0.736, 0.68, 0.788, 0.69, 0.72, 0.762],
          data: seriesData,
          type: 'line',
          lineStyle: {
            width: 0.8,
            color: '#EDFFF6',
          },
          itemStyle: {
            color: '#EDFFF6',
          },
          symbol: "diamond",
          symbolSize: 6,
        }
      ]
    }
  }

  useEffect(() => {
    getHomeData();
  }, [])

  return (
    <HomeContent header={<HomeNavBar fixed={null}></HomeNavBar>}>
      {/* 轮播图 */}
      <div className={styles.swiperWrapper}>
        {
          loading ? (
            <SpinLoading />
          ) : (
            <Swiper
              autoplay
              autoplayInterval={carouselTime}
              loop={true}
              indicatorProps={{
                color: 'white',
              }}
            >
              {
                imgBanner.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      <a className={styles.swiperItem} href={item.url} target="_blank" rel="noreferrer">
                        <img src={item.image || require('@/assets/images/banner1.jpg')} alt=""/>
                      </a>
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
          )
        }
      </div>
      <div className={styles.main}>
        {/* GAP价格 1 GAP = 0.00312 USDT */}
        <div className={styles.gapPrice}>
          {/* 左侧显示币图标 1 GAP = 0.00312 USDT */}
          <div className={styles.gapPriceLeft}>
            <img src={require('@/assets/images/coin/gap.png')} alt=""/>
            <div>
              <div className={styles.label}>{tokenName}{t('lbl_price')}</div>
              <span className={styles.price}>1 {tokenName} = {gapPrice} USDT</span>
            </div>
          </div>
          {/* 右侧显示充值按钮 */}
          <div className={styles.gapPriceRight}>
            {/*<CommonButton small className={styles.btnRecharge} onClick={() => navigate('/mine/wallet/recharge')}><img src={require('@/assets/images/dollar.png')} width={14} alt="" />{t('lbl_recharge')}</CommonButton>*/}
            <CommonButton small className={styles.btnRecharge} onClick={() => navigate('/mine/wallet/recharge')}>{t('lbl_recharge')}</CommonButton>
          </div>
        </div>
        {/*曲线图*/}
        <div className={styles.charts}>
          <ReactECharts option={getOption()} style={{ height: '135px', width: '100%' }} />
        </div>
        {/*我的资产*/}
        <div className="h25"></div>
        {/* 推荐NFT升级购买 */}
        {
          loading ? (
            <div className={styles.recommendNull}>
              <SpinLoading />
            </div>
          ) : (
            nftUpgrade.length > 0 ? (
              <div className={styles.recommend}>
                <img src={nftUpgrade[nftUpgrade.length -1].image || require('@/assets/images/1.jpg')} alt=""/>
                <div className={styles.recommendItemInfoTitle}>{nftUpgrade[nftUpgrade.length -1].name}</div>
                <div className={styles.recommendItemInfoPrice}>
                  {/* 美元图标 */}
                  <CoinGAP width={20} height={20} />
                  <div className={styles.recommendItemInfoPriceNum}>{nftUpgrade[nftUpgrade.length -1].price}</div>
                </div>
                {/* 升级按钮 */}
                <UpgradeButton small className={styles.btnBuy} onClick={() => navigate('/shop')}>{t('lbl_upgrade')}</UpgradeButton>
                {/*<div className={styles.recommendItemInfo}>*/}
                {/*  <div className={styles.recommendItemInfoTitle}>{nftUpgrade[nftUpgrade.length -1].name}</div>*/}
                {/*  <div className={styles.recommendItemInfoPrice}>*/}
                {/*    /!* 美元图标 *!/*/}
                {/*    <CoinGAP width={44} height={44} />*/}
                {/*    <div>{nftUpgrade[nftUpgrade.length -1].price}</div>*/}
                {/*  </div>*/}
                {/*  /!* 升级按钮 *!/*/}
                {/*  <BlockButton small className={styles.btnBuy} onClick={() => navigate('/shop')}>{t('lbl_upgrade')}</BlockButton>*/}
                {/*</div>*/}
              </div>
            ) : (
              <>
                <BlockButton shadow className={styles.btnBuy} onClick={() => navigate('/shop')}>{t('lbl_go_to_buy_nft')}</BlockButton>
              </>
            )
          )
        }
        <div className="h20"></div>
        {/* 我的产能值 和 累计收益 */}
        <div className={styles.myPower}>
          <div className={styles.myPowerItem}>
            {/* 我的产能值 */}
            <IconPowerFire width={24} height={24} color="##FFFFFF" />
            <div className={styles.myPowerItemRight}>
              <CommonPopover content={t('lbl_produce_introduce', {tokenName: tokenName})}>
                <div className={styles.myPowerItemTitle}>{t('lbl_my_produce')}&nbsp;<IconInfo width={12} height={12} color="#C2D6ED" className={styles.IconInfo} /></div>
              </CommonPopover>
              <div className={styles.myPowerItemNum}>{myPower} W</div>
            </div>
            {/*<div className={styles.myPowerItemLeft}>*/}
            {/*  <IconMiningValue width={44} height={44} color="#C2D6ED" />*/}
            {/*</div>*/}
            {/*<div className={styles.myPowerItemRight}>*/}
            {/*  <CommonPopover content={t('lbl_produce_introduce', {tokenName: tokenName})}>*/}
            {/*    <div className={styles.myPowerItemTitle}>{t('lbl_my_produce')}&nbsp;<IconInfo width={12} height={12} color="#C2D6ED" className={styles.IconInfo} /></div>*/}
            {/*  </CommonPopover>*/}
            {/*  <div className={styles.myPowerItemNum}>{myPower} W</div>*/}
            {/*</div>*/}
          </div>
          <div className={styles.myPowerItem}>
            {/* 累计收益 */}
            <IconPowerMoney width={24} height={24} color="##FFFFFF" />
            <div className={styles.myPowerItemRight}>
              <div className={styles.myPowerItemTitle}>{t('lbl_total_income')}</div>
              <div className={styles.myPowerItemNum}>{totalIncome} {tokenName}</div>
            </div>
          </div>
        </div>
        <div className="h25"></div>
        {/* 团战排行榜 */}
        <div className={styles.rank}>
          {/* 标题 */}
          <div className={styles.rankTitle}>
            <IconGroupRank width={24} height={24} />
            <span>{t('lbl_team_ranking')}</span>
          </div>
          {
            !loading && rankList.length === 0 && (
              <div className={styles.rankEmptyText}>{t('common.lbl_no_data')}</div>
            )
          }
          {
            rankList.length > 0 && rankList.map((item, index)=>
              <div className={styles.rankList}>
                <div className={`${styles.rankItem}`}>
                  <div className={styles.rankItemRank}>{index + 1}</div>
                  {/* 用户头像 */}
                  <img src={item.teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankItemImg} />
                  <div className={styles.rankItemName}>{item.teamName || '孟菲斯'}</div>
                </div>
              </div>)
          }
        </div>
      </div>
    </HomeContent>
  );
})
