import {configureStore, combineReducers} from "@reduxjs/toolkit";
import user from "./store/user";
import app from './store/app'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'

const persistConfig = {
    key: 'root', storage,
}

let rootReducer = combineReducers({
    user: user,
    app: app
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => //不写报错
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

let persistor = persistStore(store)

export {store, persistor}

//
// export default configureStore({
//     reducer: {
//         user: user
//     }
// })