/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-26 09:08:35
 * @FilePath: \weapp\wepay-h5\src\utils\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import {store} from '@/redux';
import {userLogout} from "@/redux/store/user";
import md5 from "md5";
import i18n from "../i18n";
import {publicKey, concatString, storeId, BASE_URL} from './config';
// import crypto from 'crypto';
import crypto from 'crypto-browserify';
import { toastError } from "@/utils/Toast";
var Buffer = require('buffer').Buffer;

// 封装一个全局使用的axios实例
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
  },
});

// 设置axios的拦截器，用于处理请求和响应
axiosInstance.interceptors.request.use(
  (config) => {
    // console.log('config', config)
    // 在发送请求之前做些什么
    // 某一些请求要求用户必须携带token, 如果没有携带, 那么直接跳转到登录页面\
    // console.log('store.getState().user', store.getState().user)
    let token = store.getState().user.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    // 多语言配置
    let language = i18n.language;
    let lan
    if(language === 'en_US') {
      lan = 'En'
    }
    else {
      lan = 'zh-HK'
    }
    config.headers['Accept-Language'] = lan;

    // params/data序列化的操作
    if (config.method === 'post' || config.method === 'POST') {
      // config.data = qs.stringify(config.data);
    } else if (config.method === 'get' || config.method === 'GET') {
      // config.params = qs.stringify(config.params);
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    //网络状态码统一处理
    // console.log('response', response)
    if (response.status === 200) {
      if (response.data.status === 200) {
        // console.log('成功返回数据', response.data.data )
        return response.data;
      } else if (response.data.status === 401) {
        //"暂未登录或token已经过期"
        store.dispatch(userLogout());
        return Promise.reject(response.data.message);
      } else if(response.data.status === 411) {
        return Promise.reject(response.data);
      } else {
        //统一错误提示
        toastError(response.data.message);
        return Promise.reject(response.data);
      }
    }
    // return Promise.reject(response.statusText);
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    toastError(error.message)
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 封装一个全局使用的网络请求方法
export function request(options) {
  return axiosInstance.request(options);
}

// 封装 GET 请求
export function get(url, params = {}, config) {
  let params1 = {...params, storeId: storeId };
  const data_token = generateEncryptedData(params1);
  const time = timeStamp();
  const paramsData = {
    data_token,
    time: time,
    store_id: params1.storeId
  }
  const access_tonken = signatureStr(paramsData);
  return axiosInstance.get(url, {
    ...paramsData,
    access_tonken: access_tonken,
    ...config
  });
}

// 封装 POST 请求
export function post(url, data = {}, config) {
  let data1 = {...data, storeId: storeId };
  console.log( "==========>url", url, "\n==========>data", data1  )
  const data_token = generateEncryptedData(data1);
  const time = timeStamp();
  const paramsData = {
    data_token,
    time: time,
    store_id: data1.storeId
  }
  const access_tonken = signatureStr(paramsData);
  return axiosInstance.post(url, {
    ...paramsData,
    access_tonken: access_tonken
  }, config);
}

export function postFile(url, files) {
  let form = new FormData();
  for (let key in files) {
    form.append(key, files[key]);
  }
  form.append('store_id', storeId);
  return axiosInstance.post(url, form);
}

/// 随机8位数字
function randomDigit() {
  let code = '';
  let possibleChars = '0123456789';
  for (let i = 0; i < 8; i++) {
    let randomIndex = Math.floor(Math.random() * possibleChars.length);
    code += possibleChars.charAt(randomIndex);
  }
  return code;
}

/// 当前时间戳
function timeStamp() {
  return Math.floor(Date.now() / 1000);
}

/// 生成签名
function signatureStr(params) {
  let result = {
    ...params
  }
  let sortedKeys = Object.keys(result).sort();
  let resultStr = '';
  for (let i = 0; i < sortedKeys.length; i++) {
    let key = sortedKeys[i];
    let value = result[key];

    if (value !== undefined && value !== null && value !== '') {
      resultStr += `${key}|${value}`;
    }
  }
  resultStr += concatString
  let signature = md5(resultStr);
  return signature;
}

/**
   * 公钥加密 -  解密用私钥解密
   * @param {*} encryptedData
   * @returns 返回加密后的请求数据
   */
const generateEncryptedData = (encryptedData) => {
  let ENCRYPT_LEN = 128;

  encryptedData = Buffer.from(JSON.stringify(encryptedData)).toString('base64');
  let encrypted = '';
  const totalLen = encryptedData.length;
  let encryptPos = 0;
  while (encryptPos < totalLen) {
    const encryptData = crypto.publicEncrypt({
      key: publicKey,
      padding: crypto.constants.RSA_PKCS1_PADDING // 指定用于填充的模式  使用PKCS#1 v1.5填充模式。这是最常用的填充模式。
    }, Buffer.from(encryptedData.substr(encryptPos, ENCRYPT_LEN)));
    encrypted += encryptData.toString('hex');
    encryptPos += ENCRYPT_LEN;
  }

  return encrypted;
}
// // 使用示例：
// request({
//     method: 'get',
//     url: '/users',
// }).then((response) => {
//     console.log(response);
// });
