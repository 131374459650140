import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import {arbitrum, bsc, bscTestnet, mainnet} from 'wagmi/chains'
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// 0. Setup queryClient
// const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'b68d37f8c3acd4b3d25218f250823154'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// const chains = [bsc, bscTestnet];
const chains = [bsc];
const wagmiConfig = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true, // Optional - true by default
    // ...wagmiOptions // Optional - Override createConfig parameters
})

// 3. Create modal
createWeb3Modal({
    wagmiConfig: wagmiConfig,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export default wagmiConfig;

export const usdtChainAddrConfig = {
    'homestead': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    'goerli': '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49',
    'bsc': '0x55d398326f99059fF775485246999027B3197955',
    'bsc-testnet': '',
}

// 代币名称 CAP
export const tokenName = 'CAP';