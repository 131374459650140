import React, {useEffect, useState} from 'react';
import styles from './CommonSelectorCoin.module.scss';
import CommonSelectorCoinItem from "./item/CommonSelectorCoinItem";
import coinCard from "../../assets/images/coin/card.png";
import coinUsdt from "../../assets/images/coin/usdt.png";
import PropTypes from "prop-types";

const CommonSelectorCoin = (props) => {

    const {options, onChange, selectedIndex} = props;

    const defaultOptions = [
        {
            coinImg: coinCard,
            title: '用本金收益結算',
            value: '0'
        },
        {
            coinImg: coinUsdt,
            title: '用USDT結算',
            value: '1'
        }
    ];

    const [selectIndex, setSelectIndex] = useState(0);
    const [innerOptions, setInnerOptions] = useState(defaultOptions);

    useEffect(() => {
        if (options && options.length > 0) {
            setInnerOptions(options);
            // console.log(options);
            if (selectedIndex !== undefined) {
                chooseItem(options[selectedIndex], selectedIndex);
            } else {
                for (let i = 0; i < options.length; i++) {
                    if (!options[i].disabled) {
                        chooseItem(options[i], i);
                        break;
                    }
                }
            }
        }
    }, [options]);


    const chooseItem = (item, index) => {
        setSelectIndex(index);

        onChange && onChange(item, index);
    }

    return (
        <div className={styles.content}>
            {
                innerOptions && innerOptions.map((item, index) => (
                    <CommonSelectorCoinItem key={index}
                                            selected={selectIndex === index}
                                            coinImg={item.coinImg}
                                            title={item.title}
                                            disabled={item.disabled}
                                            onClick={() => {
                                                if (item.disabled) {
                                                    return;
                                                }

                                                chooseItem(item, index)
                                            }}
                    ></CommonSelectorCoinItem>
                ))
            }

            {/*<CommonSelectorCoinItem selected={selectIndex === 0} coinImg={coinCard}*/}
            {/*                        title={'用本金或利息收益結算'}*/}
            {/*                        onClick={() => chooseItem(0)}*/}
            {/*></CommonSelectorCoinItem>*/}
            {/*<CommonSelectorCoinItem selected={selectIndex === 1} coinImg={coinUsdt}*/}
            {/*                        title={'用USDT結算'}*/}
            {/*                        onClick={() => chooseItem(1)}*/}
            {/*></CommonSelectorCoinItem>*/}
        </div>
    );
};

CommonSelectorCoin.prototype = {
    options: PropTypes.array,
    onChange: PropTypes.func,
}

CommonSelectorCoin.defaultProps = {}

export default CommonSelectorCoin;