/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-12 15:27:23
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\demo\Demo.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState} from 'react';
import {get, post} from "@/service/request";
import CommonPage from "../../components/commonPage/page/CommonPage";
import CommonButton from "../../components/commonButton/CommonButton";
import {useNavigate} from "react-router-dom";
import styles from "../mine/help/components/HelpLink.module.scss";
import help4 from "../../assets/images/mine/help-4.svg";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {toastSuccess} from "../../utils/Toast";
import CommonDialog from "@/components/commonDialog/CommonDialog";
import IconSwitch from "components/icon/nft/IconSwitch";



const Demo = () => {
    let navigate = useNavigate();

    //打电话
    let phone = '400-300-123';
    const copySuccess = () => {
        toastSuccess(phone + '复制成功');
    }

    return (
        <CommonPage title={'Demo'}>

            <CommonButton>基本按钮</CommonButton>
            <div className="h20"></div>
            <CommonButton small>small</CommonButton>
            <div className="h20"></div>
            <CommonButton shadow>shadow</CommonButton>
            <div className="h20"></div>
            <CommonButton>
                <IconSwitch/>图标
            </CommonButton>
            <div className="h20"></div>
            <CommonButton disabled>按钮 disabled</CommonButton>


            <div className="h20"></div>
            <div className="h20"></div>
            <div className="h20"></div>

            <CommonButton cancel>子级</CommonButton>
            <div className="h20"></div>
            <CommonButton cancel small>small</CommonButton>
            <div className="h20"></div>
            <CommonButton cancel shadow>shadow</CommonButton>
            <div className="h20"></div>
            <CommonButton cancel>图标</CommonButton>
            <div className="h20"></div>
            <CommonButton cancel disabled>按钮 disabled</CommonButton>
            <div className="h20"></div>
            <CommonButton
                block
                onClick={() =>
                CommonDialog({
                    title: '标题',
                    content: '正文内容正文内容正文内正文内容容正文内容正文内容正文内正文内容容',
                    onConfirm: () => {
                        console.log('Confirmed1111')
                    },
                })
                }
            >
                普通提示框(alert)
            </CommonButton>
            <div className="h20"></div>
            <CommonButton
                block
                onClick={() =>
                    CommonDialog({
                        type: 'confirm',
                        title: '标题',
                        content: '正文内容正文内容正文内正文内容容正文内容正文内容正文内正文内容容',
                        onConfirm: () => {
                            console.log('Confirmed222')
                        },
                    
                    })
                }
            >
                弹出确认框(confirm)
            </CommonButton>


            {/* onClick={() => navigate('/demo/styles')}
            onClick={() => navigate('/demo/components')}
            onClick={() => navigate('/demo/wallet')} */}


            <div>NET test</div>
            <div onClick={() => {
                get('http://localhost:5000/users', {_expand: 'role'})
                    .then(data => {
                        console.log(data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }}>Get
            </div>
            <div onClick={() => {
                post('http://localhost:5000/users', {
                    "username": "123",
                    "password": 123,
                    "roleState": true,
                    "region": "",
                    "roleId": 1,
                })
                    .then(data => {
                        console.log(data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }}>Post
            </div>

            <div>
                <CopyToClipboard text={phone} onCopy={() => copySuccess()}>
                    <a href={`tel:${phone}`}>
                        <img className={styles.icon} src={help4}/>
                    </a>
                </CopyToClipboard>
            </div>
        </CommonPage>
    );
};

export default Demo;