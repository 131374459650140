import React from 'react';
import './CommonInput.scss';
import {Input} from "antd-mobile";
import PropTypes from "prop-types";
import classNames from "classnames";

const CommonInput = (props) => {


    const {border, borderBottom, textAlign, layoutType, ...rest} = props;

    const inputStyle = classNames({
        'input': true,
        'input-border': borderBottom ? false : border,
        'input-border-bottom': borderBottom,
        'input-start': textAlign === 'start',
        'input-center': textAlign === 'center',
        'input-end': textAlign === 'end',
        'input-custom-filter': layoutType === 'filter',
        'active': !borderBottom,
        'active-bottom': borderBottom,
    });


    return (
        <Input {...rest} className={inputStyle}></Input>
    );
};
CommonInput.prototype = {
    border: PropTypes.bool,
    borderBottom: PropTypes.bool,
    textAlign: PropTypes.oneOf(['start', 'center', 'end']),
    type: PropTypes.oneOf(['default', 'filter']),//布局特殊类型
}

CommonInput.defaultProps = {
    border: true,
    borderBottom: false,
    textAlign: 'start',
    type: 'default',
}

export default CommonInput;
