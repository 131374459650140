const IconHome = () => {
    return (
    <svg width="1em" height="1em" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 353">
            <path id="Vector" d="M13.4248 14.1731H6.0938C5.74312 14.1731 5.51489 14.0117 5.45645 13.725C5.44531 13.6665 5.44531 13.608 5.44531 13.5468V7.23353C5.44531 6.83547 5.6624 6.62113 6.06597 6.62113H13.422V6.42906V0.811684C13.422 0.552806 13.5445 0.349601 13.7532 0.268876C13.9926 0.177016 14.2041 0.21877 14.385 0.410841C14.488 0.519403 14.6021 0.619613 14.7078 0.728175C16.0494 2.0671 17.3881 3.40603 18.7296 4.74218C20.4524 6.45968 22.1724 8.17718 23.8952 9.89746C24.1819 10.1842 24.1847 10.4848 23.9008 10.766C21.4377 13.2267 18.9745 15.6874 16.5086 18.1454C15.8072 18.8441 15.1031 19.54 14.4017 20.2387C14.2208 20.4196 14.0204 20.4975 13.7755 20.4057C13.5361 20.3138 13.4248 20.1273 13.4248 19.8295V14.1731Z" fill="currentColor"/>
            <path id="Rectangle 87 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M4 2C2.89543 2 2 2.89543 2 4V16.6667C2 17.7712 2.89543 18.6667 4 18.6667H8.77778C9.33006 18.6667 9.77778 19.1144 9.77778 19.6667C9.77778 20.219 9.33006 20.6667 8.77778 20.6667H4C1.79087 20.6667 0 18.8758 0 16.6667V4C0 1.79086 1.79086 0 4 0H8.77778C9.33006 0 9.77778 0.447715 9.77778 1C9.77778 1.55228 9.33006 2 8.77778 2H4Z" fill="currentColor"/>
        </g>
    </svg>
    );
};

export default IconHome;
