import React, {useEffect, useState} from 'react';
import styles from './CommonSelectorCoinItem.module.scss';
import PropTypes from "prop-types";


const CommonSelectorCoinItem = (props) => {

    const {coinImg, title, selected, onClick, className, disabled} = props;

    const stylesContent = [styles.content, selected ? styles.contentSelected : '', disabled ? styles.contentDisabled : '', className].join(' ');

    return (<div className={stylesContent} onClick={() => {
        if (disabled) {
            return;
        }
        onClick && onClick();
    }}>
        {
            coinImg &&
            <img className={styles.coin} src={coinImg}></img>
        }
        <div className={styles.title}>{title}</div>
        <div className={styles.radio}></div>
    </div>);
};

CommonSelectorCoinItem.prototype = {
    coinImg: PropTypes.string, title: PropTypes.string, selected: PropTypes.bool, onClick: PropTypes.func,
}

export default CommonSelectorCoinItem;