import React from 'react';
import {Button} from "antd-mobile";
import styles from './CommonButton.module.scss';
import PropTypes from "prop-types";

const CommonButton = (props) => {

    const {children, className, cancel, reset, small, onClick, disabled, shadow, ...rest} = props;
    const buttonStyle = [
        styles.button, styles.custom_btn, styles.btn_11,
        cancel ? styles.cancel : styles.primary,
        reset ? styles.reset : '',
        small ? styles.buttonSmall : '',
        disabled ? styles.buttonDisabled : '',
        shadow ? styles.buttonShadow : '',
        className].join(' ');
    const handleClick = () => {
        !disabled && onClick && onClick();
    }

    return (
        // <Button {...rest} block shape='rounded' color='primary'>
        //     {props.children}
        // </Button>
        <Button className={buttonStyle} onClick={handleClick} {...rest}>
            {children}
        </Button>
    );
};

CommonButton.prototype = {
    cancel: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
};

CommonButton.defaultProps = {
    cancel: false,
    small: false,
    disabled: false,
    shadow: false
};

export default CommonButton;
