export default function Page(title, path, element, children, searchKey, search, searchTitle) {
    return {
        title: title,
        path: path,
        element: element,
        children: children,
        searchText: searchKey,//翻译字段
        search: search,//true false
        searchTitle: searchTitle,//搜索标题
    }
}