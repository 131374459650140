import React, {useEffect, useState} from 'react';
import CommonPage from "../../components/commonPage/page/CommonPage";
import styles from './rule.module.scss';
import {useTranslation} from "react-i18next";

const PrivateRule = () => {
    let {t} = useTranslation();

    const tips = `
   <p>感谢您使用 crydit 的服务。我们非常重视您的隐私保护，并致力于保护您的个人信息安全。请仔细阅读以下隐私条款，了解我们收集、使用和保护您的个人信息的方式。</p>

<h2>1. 信息收集与使用</h2>
<p>1.1 在您使用我们的服务时，我们可能会收集您提供的个人信息，例如姓名、联系方式和地址等，以便向您提供服务并进行必要的沟通。</p>
<p>1.2 我们会严格遵守法律法规的要求，对您的个人信息进行保密，并不会将其泄露给未经授权的第三方，除非获得您的明确同意或法律要求。</p>

<h2>2. 信息存储与保护</h2>
<p>2.1 我们将采取必要的技术和组织措施，确保您的个人信息在存储和传输过程中的安全性。</p>
<p>2.2 我们将合理采取措施保护您的个人信息免遭未经授权的访问、使用或披露，并定期进行安全评估和风险管理，确保信息安全。</p>

<h2>3. Cookie 和类似技术</h2>
<p>3.1 为了提供更好的用户体验和个性化服务，我们可能会使用 Cookie 和类似的技术来收集和存储您的偏好设置和其他相关信息。</p>
<p>3.2 您可以根据您的浏览器设置选择接受或拒绝 Cookie。如果您选择禁用 Cookie，可能会影响您使用我们的服务的某些功能。</p>

<h2>4. 隐私权的行使</h2>
<p>4.1 您有权访问、更正、删除您的个人信息，以及撤回您对我们使用您个人信息的同意。</p>

<br>
<p>请注意，我们将根据适用法律的要求和合理的商业实践，及时处理您的请求并采取必要的措施。</p>
<br>
<p>最后，我们建议您在使用我们的服务之前仔细阅读本隐私条款，并定期查看更新。如对条款内容有任何疑问或建议，请随时与我们联系。</p>

        `

    return (
        <CommonPage title={'Crydit 隐私条款'}>
            <div dangerouslySetInnerHTML={{__html: tips}} className={`${styles.rule}`}>
            </div>
        </CommonPage>
    );
};

export default PrivateRule;