import styles from './Wallet.module.scss';
import React, {useEffect, useState, memo} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonPopover from "@/components/commonPopover/CommonPopover";
import accountApi from "@/service/accountApi";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setUser} from "@/redux/store/user";
import { getCoinData } from '@/utils/constants';
import {ReactComponent as IconInfo } from '@icon/info.svg';
import {ReactComponent as IconWithdraw } from '@icon/Withdraw.svg';
import {ReactComponent as IconRecharge } from '@icon/Recharge.svg';
import {ReactComponent as IconExchange } from '@icon/Exchange.svg';
import {ReactComponent as IconRecord } from '@icon/Record.svg';

export default memo(function Wallet() {
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  // �û��ʲ��б�
  const [userAssetsList, setUserAssetsList] = useState([]);
  // loading
  const [loading, setLoading] = useState(false);

  // ��ȡ�û��ʲ��б�
  const getUserAssetsList = () => {
    setLoading(true);
    accountApi.getUserAssetsList().then(res => {
      console.log(res)
      setUserAssetsList(res.data)
    })
    .finally(() => {
      setLoading(false);
    })
  }

  // �û���Ϣ
  const getUserInfo = () => {
    accountApi.getUserInfo().then(res => {
      console.log(res);
      setUserInfo(res.data)
      dispatch(setUser(res.data));
    })
    .catch(err => {
      console.log(err)
    });
  }

  useEffect(() => {
    getUserInfo();
    getUserAssetsList();
  }, []);

  return (
    <CommonPage loading={loading} title={t('lbl_my_wallet')} flex className={styles.wallet}>
      {/* �ҵķ��� */}
      <div className={styles.score}>
        {/*<div className={styles.title}>{t('lbl_my_score')}</div>*/}
        <div className={styles.listScore}>
          <div className={styles.itemScore}>
            <img src={require('@/assets/images/trophy.png')} alt=''  />
            <div>
              <CommonPopover content={t('lbl_contribution_score_desc')}>
                <div className={styles.itemScoreLabel}>
                  {t('lbl_contribution_score')}
                  {/* ���׷�˵�� */}
                  <IconInfo className={styles.info} />
                </div>
              </CommonPopover>
              <div className={styles.itemScoreValue}>{userInfo.score}</div>
            </div>
          </div>
          {/*<div className={styles.itemScore}>
            <img src={require('@/assets/images/flag.png')} alt='' width={64} />
              <CommonPopover content={t('lbl_team_score_desc')}>
                <div className={styles.itemScoreLabel}>
                  {t('lbl_team_score')}
                  { ��ս��˵�� /}
                  <IconInfo className={styles.info}/>
                </div>
              </CommonPopover>
            <div className={styles.itemScoreValue}>{userInfo.teamScore}</div>
          </div> */}
        </div>
      </div>
      {/* �ҵ��ʲ� */}
      <div className={styles.assets}>
        <div className={styles.title}>{t('lbl_my_assets')}</div>
        <div className={styles.listAssets}>
          {
            userAssetsList.map((item, index) => {
              return (
                <div className={styles.itemAssets} key={index}>
                  <div className={styles.itemAssetsLabel}>
                    <img src={getCoinData(item.coinName).img} alt="" className={styles.iconCoin} /> {item.blockCoinName}
                  </div>
                  <div className={styles.itemAssetsValue}>{item.balance}</div>
                </div>
              )
            })
          }
          <div className={styles.itemAssets}>
            <div className={styles.itemAssetsLabel}>
              <img src={require('@/assets/images/ecology.png')} alt='' className={styles.iconCoin} />
              {t('lbl_ecology_score')}
            </div>
            <div className={styles.itemAssetsValue}>{userInfo.ecologicalPoints}</div>
          </div>
        </div>
      </div>
      {/* ����ֵ(����) */}
      <div className={styles.assets}>
        <div className={styles.title}>{t('lbl_my_power')}</div>
        <div className={styles.listAssets}>
          <div className={styles.itemAssets}>
            <div className={styles.itemAssetsLabel}> {t('lbl_total_power')} </div>
            <div className={styles.itemAssetsValue}>{((userInfo.personPower + userInfo.sameLevelPower + userInfo.communityPower + userInfo.share1Power + userInfo.share2Power + userInfo.share3Power) / 100.0).toFixed(2)} W</div>
          </div>
        </div>
        <div className={styles.listPowers}>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_individual')} </div>
            <div className={styles.itemAssetsValue}>{(userInfo.personPower / 100.0).toFixed(2)} W</div>
          </div>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_share_1')}</div>
            <div className={styles.itemAssetsValue}>{(userInfo.share1Power / 100.0).toFixed(2)} W</div>
          </div>
        </div>
        <div className={styles.listPowers}>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_peer')} </div>
            <div className={styles.itemAssetsValue}>{(userInfo.sameLevelPower / 100).toFixed(2)} W</div>
          </div>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_share_2')}</div>
            <div className={styles.itemAssetsValue}>{(userInfo.share2Power / 100).toFixed(2)} W</div>
          </div>
        </div>
        <div className={styles.listPowers}>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_community')} </div>
            <div className={styles.itemAssetsValue}>{(userInfo.communityPower / 100).toFixed(2)} W</div>
          </div>
          <div className={styles.itemPowers}>
            <div className={styles.itemAssetsLabel}> {t('lbl_share_3')}</div>
            <div className={styles.itemAssetsValue}>{(userInfo.share3Power / 100).toFixed(2)} W</div>
          </div>
        </div>
      </div>
      {/* ��ť�飬���� ��ֵ */}
      <div className={styles.btnGroup}>
        {/* ���� */}
        <CommonButton className={styles.button} onClick={() => navigate('/mine/wallet/withdraw')}>
          <IconWithdraw />{t('lbl_withdraw')}
        </CommonButton>
        {/* ��ֵ */}
        <CommonButton onClick={() => navigate('/mine/wallet/recharge')}>
          <IconRecharge/>{t('lbl_recharge')}
        </CommonButton>
        {/* �һ� */}
        <CommonButton onClick={() => navigate('/mine/wallet/exchange')}>
          <IconExchange />{t('lbl_exchange')}
        </CommonButton>
        {/* Ǯ����¼ */}
        <CommonButton onClick={() => navigate('/mine/wallet/record')}>
          <IconRecord />{t('lbl_wallet_record')}
        </CommonButton>
      </div>
      <div className='h24'></div>
    </CommonPage>
  );
})
