/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-10-10 15:09:51
 * @FilePath: \weapp\wepay-h5\src\pages\home\Home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CommonNavBar from "../../components/commonNavBar/CommonNavBar";
import { Radio, Space } from 'antd-mobile'
import {useDispatch, useSelector} from "react-redux";
import {setLang} from "../../redux/store/app";
import styles from "./Locales.module.scss"
import classNames from 'classnames';
import { useLocale } from '@/LocaleContext';
import CommonPage from "@/components/commonPage/page/CommonPage";
import {ReactComponent as IconRadioCheked } from '@icon/RadioChecked.svg';
import {ReactComponent as IconRadioUnCheck } from '@icon/RadioUnCheck.svg';
import China from 'assets/images/China.png';
import US from 'assets/images/United Kingdom.png';

const Home = () => {
  const {t, i18n} = useTranslation();
  const { locale, setLocale } = useLocale();
  const SwitchLang = (value) =>{ 
    i18n.changeLanguage(value);
    setLocale(value);
    localStorage.setItem('locale', value);
  }
  const locales = [
    {title:'繁体中文', icon: China, key:'zh_HK'},
              //<img src={require('@/assets/image/logo_dark.png')} alt="logo" />
    {title:'English', icon: US, key:'en_US'}
  ]
  
  return (
    <CommonPage title={t('lbl_language_switch')} padding0 flex className={styles.locales}>
      <Radio.Group defaultValue={locale} onChange={SwitchLang}>
        <div className={styles.radioGroup}>
          { 
            locales.map((item) => {
              return (
                <Radio value={item.key} key={item.key} 
                  style={{'--icon-size': '18px','--font-size': '14px','--gap': '6px' }}
                  icon={checked =>
                    checked ? (
                      <IconRadioCheked style={{ color: 'rgba(99, 255, 125, 1)' }} />
                    ) : (
                      <IconRadioUnCheck style={{ color: '#FFFFFF' }} />
                    )
                  }
                >
                  <div className={ styles.item }>
                    <img src={item.icon} alt="" />
                    <span className='text-14' style={{marginLeft: '5px'}}>{item.title}</span> 
                  </div>
                </Radio>
              )
            })
          }
        </div>
      </Radio.Group>
    </CommonPage>
  );
};


export default Home;
