import React, {useEffect, useState} from 'react';
import styles from "./CommonButton2.module.scss";

const CommonButton2 = (props) => {


    const {children, className, small, ...rest} = props;

    return (
        <div className={`${styles.button} ${styles.buttonText} ${className} ${small ? styles.small : ''}`} {...rest}>
            {/*<div className={styles.buttonText}>{children}</div>*/}
            {children}
        </div>
    );
};

export default CommonButton2;
