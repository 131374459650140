/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-15 10:48:24
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-10-24 10:32:42
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\i18n\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import i18next, {Resource, KeyPrefix, ResourceLanguage, Namespace} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import zh_HK from './locales/zh.js';
import en_US from './locales/en.js';

// 创建 i18n 实例
const i18nextInstance = i18next.createInstance();
// 注入插件，initReactI18next 是一个 3rdParty 类型的插件，
// 也就是把实例给第三方插件，让其完全可以获取 i18n 实例所有信息
i18nextInstance.use(initReactI18next)
    .use(LanguageDetector);
// 所有翻译资源
const resources = {
    en_US: {
        translation: en_US
    }, zh_HK: {
        translation: zh_HK
    }
}
const preload = ['zh_HK', 'en_US']

// i18n 实例初始化内容
i18nextInstance.init({
    resources,
    preload,
    fallbackLng: 'en_US',
    lng: localStorage.getItem('locale') || 'en_US',
    debug: process.env.NODE_ENV === "development",
    detection: {
        caches: ['localStorage'],/*, 'sessionStorage', 'cookie'*/
        convertDetectedLanguage: (lng) => {
            if (preload.includes(lng)) {
                return lng;
            }
        }
    }

}, (err) => {
    if (err) return console.log("something went wrong loading", err);
});

export default i18nextInstance;