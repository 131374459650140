import React, {useEffect, useState} from 'react';
import styles from './CommonSendCode.module.scss';

import {Input} from "antd-mobile";
import PropTypes from "prop-types";
import classNames from "classnames";
import {toastError, toastHide, toastLoading, toastSuccess} from "../../utils/Toast";
import accountApi from "../../service/accountApi";
import {checkEmail, checkPhone} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const CommonSendCode = (props) => {


    const {type, border, borderBottom, onSendEmail, email, ...rest } = props;


    const [inputEmail, setInputEmail] = useState('');
    const [isVerifyEmail, setIsVerifyEmail] = useState(false);
    const [isChangeEmail, setIsChangeEmail] = useState(true);
let {t} = useTranslation();

    //0 未开始 1 开始 2 结束
    const [countDownStatus, setCountDownStatus] = useState(0);
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if(email){
            setInputEmail(email);
            setIsChangeEmail(false);
        }
    },[email]);

    useEffect(() => {
        // 创建定时器
        const interval = setInterval(() => {
            if (countDownStatus === 1) {
                if (countDown === 0) {
                    setCountDown(0);
                    setCountDownStatus(2)
                } else {
                    setCountDown(countDown - 1);
                }
            }
        }, 1000);

        // 返回一个函数，用于在组件卸载时清除定时器
        return () => clearInterval(interval);
    }, [countDown, countDownStatus]);


    const startCountDown = () => {
        setCountDownStatus(1);
        setCountDown(59);
    }

    const contentStyle = classNames({
        [styles.content]: true,
        [styles.contentBorder]: borderBottom ? false : border,
        [styles.contentBorderBottom]: borderBottom,
        [styles.contentActive]: !borderBottom,
        [styles.contentActiveBottom]: borderBottom,
        [styles.contentError]: isVerifyEmail,
    })

    const onVerifyCode = () => {
        if (type === 'email') {
            if (!checkEmail(inputEmail)) {
                setIsVerifyEmail(true)
                toastError(t('msg_incorrect_email'))
                return;
            }
        } else if (type === 'phone') {
            if (!checkPhone(inputEmail)) {
                setIsVerifyEmail(true)
                toastError(t('msg_incorrect_phone'))
                return;
            }
        }

        setIsVerifyEmail(false)

        onSendEmail && onSendEmail(inputEmail);

        toastLoading();
        accountApi.getVerifyCode(inputEmail)
            .then(data => {
                console.log(data)
                toastHide();
                toastSuccess(t('msg_verify_code_sent'))
                startCountDown();
            })
            .catch(err => {
                toastHide();
            });
    }


    const showCountDownArea = () => {
        if (countDownStatus === 0) {
            return <div className={styles.right} onClick={onVerifyCode}>{t('lbl_send_code')}</div>;
        }
        if (countDownStatus === 1) {
            return <div className={styles.right}>{countDown}s</div>;
        }
        if (countDownStatus === 2) {
            return <div className={styles.right} onClick={onVerifyCode}>{t('lbl_get_code_again')}</div>;
        }
    }

    console.log(isChangeEmail);

    return (
        <div className={contentStyle}>
            <Input value={inputEmail}
                error={isVerifyEmail}
                disabled={countDownStatus === 1 || !isChangeEmail}
                onChange={(value) => {
                    setInputEmail(value);
                    onSendEmail && onSendEmail(value);
                }}
                placeholder={t('lbl_placeholder_email')}
            />
            {
                showCountDownArea()
            }
        </div>
    );
};
CommonSendCode.prototype = {
    border: PropTypes.bool,
    borderBottom: PropTypes.bool,
}

CommonSendCode.defaultProps = {
    border: true,
    borderBottom: false,
    type: 'email'
}

export default CommonSendCode;