/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:31:00
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';
import {InfiniteScroll} from "antd-mobile";

import { useLocation, useNavigate } from "react-router-dom";
import styles from './Nft.module.scss';
import { useTranslation } from "react-i18next";
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import {useDispatch, useSelector} from "react-redux";

import { Swiper,Form,SpinLoading } from "antd-mobile";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import CommonPopup from 'components/commonPopup/CommonPopup';
import CommonInput from "components/commonInput/CommonInput";
import CommonSelector from "components/commonSelector/CommonSelector";
import CommonButton from "components/commonButton/CommonButton";

import { ReactComponent as IconR } from '@icon/pool/IconR.svg';
import { ReactComponent as IconSR } from '@icon/pool/IconSR.svg';
import { ReactComponent as IconSSR } from '@icon/pool/IconSSR.svg';

import IconFilter from "components/icon/IconFilter"
import gap from "assets/images/coin/gap.png"
import classNames from 'classnames';
import nftApi from "service/nftApi";

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [filterVisible,setFilterVisible] = useState(false);
  const [param,setParam] = useState({pageNo:1,pageSize:20});
  const [list,setList] = useState([])
  const [banner,setBanner] = useState([])

  const rareType = useSelector(state =>  state.app.heroLevel);
  
  const sortType=[
    {label:t("lbl_sort_price_asc"),value:"1",filter:{"orderBy":"price","orderType":"ASC"}},
    {label:t("lbl_sort_price_desc"),value:"2",filter:{"orderBy":"price","orderType":"DESC"}},
    {label:t("lbl_sort_level_asc"),value:"3",filter:{"orderBy":"level","orderType":"ASC"}},
    {label:t("lbl_sort_level_desc"),value:"4",filter:{"orderBy":"level","orderType":"DESC"}},
  ];

  const nftType=[
    {label:t("nft.hero"),value:1},
    {label:t("nft.skin"),value:0},
  ];

  useEffect(() => {
    // getNftList();
    getBanner();
  }, []);

  useEffect(() => {
    const data = form.getFieldsValue(true)
    console.log( Object.keys(data).length >0)
    // && Object.keys(data).length
    if(param.pageNo ===1  ){
      getNftList();
    }
  }, [param]);

  const more = ()=>{ 
    setParam(prevState => { 
      prevState.pageNo++;
      return prevState
    })
    return getNftList();
  }

  const getBanner=()=>{ 
    nftApi.nftBanner().then(res => {
      setBanner( res.data )
    }).catch(err => {})
  }

  const search = async () => {
    setList([])
    const data = form.getFieldsValue(true);
    const sortIteme = sortType.find(item => item.value == (data.sort&& data.sort[0]))||{}
    setParam(prevState => { 
      prevState.pageNo=1;
      prevState.id=data.id;
      prevState.name=data.name;
      prevState.level= data.level?.join(',');
      prevState.type= data.type?.join(',');
      return {...prevState, ...sortIteme.filter }
    })
    setFilterVisible(false) 
  }


  const reset = async () => {
    form.resetFields();
  }

  const getNftList = async()=>{ 
    toastLoading();
    return await nftApi.nftList(param).then(res => {
        setList( res.data )
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }
  
  const bannerItems = ()=>{ 
    return banner.map((item, index) => (
      <Swiper.Item key={index}>
        <div
          className={styles.bannerContent}
          style={{ background:`url(${item.image}) no-repeat center/ 100% auto` }}
          onClick={() => { }}
        >
        </div>
      </Swiper.Item>
    ))
  } 

  const nftItem=(item)=>{ 
    const rare = item.type === 1?
    rareType.find(items=>items.level === item.level )||rareType.find(items=>items.level === "1") :
    rareType.find(items=>items.level === "1")
    console.log(item.type, rare)

    const type = nftType.find(items=> items.value === item.type )
    const linkDetail=item.type === 1?
      ()=>navigate('hero',{state:{id:item.id,info:item}}):
      ()=>navigate('material',{state:{id:item.id,info:item}});
    return (
    <div className={classNames(styles.grid_item, styles[rare?.style] )} 
      style={{ "backgroundImage": `url(${item.image}),linear-gradient(180deg, #123556 0%, #071A2C 100%)` }}
      key={item.id}  onClick={linkDetail} >
      <div className='flex algn-center justify-end text-12 align-center'>
        {item.type=="1" && rare?.tag }
      </div>
      <div className='text-center flex flex-direction text-12'>
        <div>{item.name}</div>
        <div className={styles.coin} >
          <img src={gap}/>
          {item.price +item.token}
        </div>
      </div>
    </div> )
  }
           
  return (
    <CommonPage tt={t('lbl_battlefield')}  flex padding0 title={t('lbl_nft_market')} 
      header={<CommonNavBar onBack={()=>navigate("/mine")}>{t('lbl_nft_market')}</CommonNavBar>}
    >
      
      <div className={styles.swiperWrapper}>
        {
          banner.length<=0 ?  <SpinLoading />: 
          <Swiper
            autoplay
            autoplayInterval={2000}
            loop={true}
            indicatorProps={{color: 'white'}}
          >{bannerItems()}</Swiper>
        }
      </div>
      <div className={styles.mainContent}>
        <div className={styles.filter} > 
          <span>{t("nft.hot")}</span>
          <span className='flex align-center' onClick={()=>setFilterVisible(true)}><IconFilter /></span>
        </div>
        <div className={styles.grid} style={{padding:"16px 0" }}>
          {list.map(item=>nftItem(item))}
        </div>
        {/* <InfiniteScroll loadMore={ more } hasMore={list.length < total} threshold={320}></InfiniteScroll> */}
      </div>
      <CommonPopup visible={filterVisible} getVisible={setFilterVisible} footer={
        <div className={styles.action}>
          <CommonButton cancel onClick={reset}>{t("common.reset")}</CommonButton>
          <CommonButton className={styles.confirm} onClick={search}>{t('common.confirm')}</CommonButton>
        </div>
          }>
        <div className='popupContent'>
          <Form className={styles.form} form={form} >
            <div className='popupContent'>
              <Form.Item label={t("nft.name")} name="name">
                <CommonInput placeholder={t("lbl_input_nft_name")} type='text' />
              </Form.Item>
              {/* <Form.Item label={t("nft.no")} name="id">
              <CommonInput placeholder={t("lbl_input_nft_id")} type='text' />
              </Form.Item> */}
              <Form.Item label={t("nft.type")} name="type">
                <CommonSelector columns={2} options={nftType}></CommonSelector>
              </Form.Item>
              <Form.Item label={t("nft.rare")} name="level" >
                <CommonSelector columns={2} options={rareType} fieldNames={{label: 'name', value: 'level'}}></CommonSelector>
              </Form.Item>
              <Form.Item label={t("nft.sort")} name="sort">
                <CommonSelector columns={2} options={sortType}></CommonSelector>
              </Form.Item>
            </div>
          </Form>
        </div>
      </CommonPopup>
    </CommonPage>
  );
})  
