import Page from "./Page";
import Invitation from "../pages/mine/invitation/Invitation";
import Wallet from "../pages/mine/wallet/Wallet";
import Withdraw from "../pages/mine/wallet/withdraw/Withdraw";
import Recharge from "../pages/mine/wallet/recharge/Recharge";
import Record from "../pages/mine/wallet/record/Record";
import Exchange from "../pages/mine/wallet/exchange/Exchange";
import Community from "../pages/mine/community/Community";
import CommunitySettings from "../pages/mine/community/settings/Settings";
import CommunitySearch from "../pages/mine/community/search/Search";
import i18next from '../i18n/index.js'

const mineRoute = [
  Page('我的邀请码', '/mine/invitation', <Invitation/>),
  Page('我的钱包', '/mine/wallet', <Wallet/>),
  Page('提现', '/mine/wallet/withdraw', <Withdraw/>),
  Page('充值', '/mine/wallet/recharge', <Recharge/>),
  Page('记录', '/mine/wallet/record', <Record/>),
  Page('兑换', '/mine/wallet/exchange', <Exchange/>),
  Page('我的社区', '/mine/community', <Community/>),
  Page('社区设置', '/mine/community/setting', <CommunitySettings/>),
  Page('查询级别业绩', '/mine/community/search', <CommunitySearch/>),
]

export default mineRoute;