import React from 'react';
import styles from './HomeContent.module.scss';

const HomeContent = (props) => {
  return (
    <div className={styles.body}>
      {/*<div className={styles.header}>*/}
      {/*</div>*/}
      {props.header}
      <div className={`${styles.content} ${props.content2 ? styles.content2 :''}`}>
        {props.children}
      </div>
    </div>
  );
};

HomeContent.defaultProps = {
}

export default HomeContent;
