import {parseUnits} from "viem";
import BigNumber from "bignumber.js";

export const formatterBalance = (balance) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD',
    });

    return formatter.format(balance);
}

export const checkEmail = (email) => {
    console.log(email )
    if (!email) { return false }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export const checkPassword = (pwd) => {
    if(!pwd){ return false };
    const pwdRule = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
    return pwdRule.test(pwd);
}


export const checkPayPassword = (pwd) => {
    if(!pwd){ return false };
    const pwdRule =/^[0-9]\d{5}$/;
    return pwdRule.test(pwd);
}

export const checkPasswordAgain = (pwd,pwdAgain) => {
    return (pwd === pwdAgain)
}

export const checkPhone = (input) => {
    const regex = /^\d+$/; // 匹配由数字组成的字符串
    return regex.test(input);
};

/**
 * 昵称校验 2-14个字符
 * @param {*} nickname 
 * @returns true or false
 */
export const checkName = (nickname) => {
    if (!nickname) { return false }
    const regex = /^.{2,14}$/;
    return regex.test(nickname);
}

// ^ 和 $：分别表示字符串的开始和结束。
// (?=.*[A-Z])：表示至少包含一个大写字母。
// (?=.*[a-z])：表示至少包含一个小写字母。
// (?=.*\d)：表示至少包含一个数字。
// [A-Za-z\d]{8,16}：表示由字母和数字组成的字符串，长度在8到16之间。


export function formatCardNumber(cardNumber) {
    if (!cardNumber) {
        return ''
    }
    // Remove any non-digit characters from the card number
    const digitsOnly = cardNumber.replace(/\D/g, '');

    // Split the card number into groups of four digits
    const groups = digitsOnly.match(/(\d{1,4})/g);

    // Join the groups with a space in between
    const formattedNumber = groups.join(' ');

    return formattedNumber;
}

export function formatCardNumberHidden(cardNumber) {
    if (!cardNumber) {
        return ''
    }
    // Remove any non-digit characters from the card number
    const digitsOnly = cardNumber.replace(/\D/g, '');

    // Split the card number into groups of four digits
    const groups = digitsOnly.match(/(\d{1,4})/g);

    let result = [
        '****',
        '****',
        '****',
        groups[groups.length - 1]
    ]
    // Join the groups with a space in between
    const formattedNumber = result.join(' ');

    return formattedNumber;
}

export function parseUnitsWrapper(value, decimals) {
    if (value === 0) {
        return 0;
    }
    try {
        return parseUnits(value, decimals);
    } catch (e) {
        return 0;
    }
}

/**
 * 过滤选择全部和子选项。
 */
export function filterAllChangeFunc(v) {
    if (v[v.length - 1] === 'all') {
        v = ['all']
    } else {
        v = v.filter(item => item !== 'all');
    }
    return v;
}

export function toFixedNum(value, decimals) {
    if (value === 0) {
        return 0;
    }
    try {
        return new BigNumber(value).toFixed(decimals).replace(/\.?0+$/, '');
    } catch (e) {
        return 0;
    }
}

//  复制到剪切板
export function copyToClipboard(text) {
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
        document.execCommand('copy');
    }
    document.body.removeChild(input);
}

/**
 * 格式化钱包地址,只显示前6位和后4位
 * @param {*String} address 
 * @returns 
 */
export function formatWalletAddress(address) {
    if (!address) {
        return ''
    }
    return address.substring(0, 7) + '...' + address.substring(address.length - 5, address.length);
}

/**
 * 字符串截取，超过长度显示..., 中文字符算两个长度
 * @param {*} str 
 * @param {*} length 
 * @returns 
 */
export function formatString(str, length) {
    if (!str) {
        return ''
    }
    let len = 0;
    let result = '';
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
            len += 2;
        } else {
            len++;
        }
        if (len > length) {
            result += '...';
            break;
        }
        result += str[i];
    }
    return result;
}
