/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-21 17:36:52
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-09-21 18:31:35
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\icon\menu\IconHelp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const  Icon = () => {
    return (
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9993 17.277V19.6104C12.1428 19.6104 10.3624 20.3478 9.0496 21.6606C7.73685 22.9734 6.99935 24.7538 6.99935 26.6104H4.66602C4.66602 24.135 5.64935 21.761 7.39969 20.0107C9.15003 18.2603 11.524 17.277 13.9993 17.277ZM13.9993 16.1104C10.1318 16.1104 6.99935 12.9779 6.99935 9.11035C6.99935 5.24285 10.1318 2.11035 13.9993 2.11035C17.8668 2.11035 20.9993 5.24285 20.9993 9.11035C20.9993 12.9779 17.8668 16.1104 13.9993 16.1104ZM13.9993 13.777C16.5777 13.777 18.666 11.6887 18.666 9.11035C18.666 6.53202 16.5777 4.44369 13.9993 4.44369C11.421 4.44369 9.33268 6.53202 9.33268 9.11035C9.33268 11.6887 11.421 13.777 13.9993 13.777ZM17.0268 22.891C16.8789 22.2685 16.8789 21.62 17.0268 20.9975L15.8695 20.329L17.0362 18.3084L18.1935 18.9769C18.658 18.5369 19.2196 18.2124 19.8327 18.0295V16.6937H22.166V18.0295C22.7867 18.2139 23.3467 18.5429 23.8052 18.9769L24.9625 18.3084L26.1292 20.329L24.9719 20.9975C25.1196 21.6196 25.1196 22.2677 24.9719 22.8899L26.1292 23.5584L24.9625 25.579L23.8052 24.9105C23.3407 25.3505 22.7791 25.675 22.166 25.8579V27.1937H19.8327V25.8579C19.2196 25.675 18.658 25.3505 18.1935 24.9105L17.0362 25.579L15.8695 23.5584L17.0268 22.891ZM20.9993 23.6937C21.4635 23.6937 21.9086 23.5093 22.2368 23.1811C22.565 22.8529 22.7493 22.4078 22.7493 21.9437C22.7493 21.4796 22.565 21.0344 22.2368 20.7062C21.9086 20.3781 21.4635 20.1937 20.9993 20.1937C20.5352 20.1937 20.0901 20.3781 19.7619 20.7062C19.4337 21.0344 19.2493 21.4796 19.2493 21.9437C19.2493 22.4078 19.4337 22.8529 19.7619 23.1811C20.0901 23.5093 20.5352 23.6937 20.9993 23.6937Z" fill="white"/>
</svg>
    );
};

export default Icon;
