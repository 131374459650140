/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:35:48
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import styles from './Nft.module.scss';
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

import { toastHide, toastLoading } from "utils/Toast";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonButton from "components/commonButton/CommonButton";
import gap from "assets/images/coin/gap.png"
import nftApi from "service/nftApi";
import classNames from 'classnames';
import CommonDialog from "@/components/commonDialog/CommonDialog";

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.includes("shop")?"shop":"nft";
  const id = location?.state?.id
  const info = location?.state?.info
  const [myHero,setMyHero] = useState({});
  
  const rareType = useSelector(state =>  state.app.heroLevel);
  const rare = rareType.find(item=>item.level == info.level) || rareType.find(items=>items.level === "1")

  useEffect(() => {
    getNftList()
  }, []);
  const getNftList = ()=>{ 
    toastLoading();
    nftApi.nftMyList({pageNo:1,pageSize:1,type:1}).then(res => {
        setMyHero(res.data.list[0] )
        console.log( "myHero",res.data.list[0])
        console.log( "shopHero",info)
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  const header = () => {
    return (
      <div className={styles.header}>{t('common.tip')}</div>
    )
  }

  // 点击升级，弹窗确认框
  const handleUpgrade = ()=>{
    CommonDialog({
      //title: t("common.tip"),
      type: 'confirm',
      title: header(),
      content: t("nft.upgradeTip"),
      okText: t('common.confirm'),
      onConfirm:()=>{
        navigate('/shop/hero/buy',{state:{id,info,type:1}})
      }
    })
  }

  return (
      <CommonPage tt={t('lbl_battlefield')}  flex  title={t('nft.heroNFT')}>
        <div className={ classNames(styles.nft,styles[rare?.style]) } style={{ "backgroundImage": `url(${info.image}),linear-gradient(180deg, #123556 0%, #071A2C 100%)` }}></div>
        <div className={styles.intro}>
          <div className='flex align-center justify-between text-16'>
            <div className={classNames(styles.tag,styles[rare?.style])}>{rare?.name}</div>
            {pathname!="shop"&& <div>{"#"+info.id}</div>}
          </div>
          <div className='flex align-center justify-between text-20 text-bold'>
            <div className='text-bold'>{info.name}</div>
            { pathname==="shop"?"": <div className={styles.coin}><img src={gap}/><span>{info.price}</span> </div> }
          </div>
        </div>
        <p className={classNames(styles.intro,styles.border,'text-12 text-aliceBlue')}>
          {info.brief}
        </p>

        {/* disabled={ myHero.level>=info.level} */}
        { pathname==="nft"&&
          <div className={styles.intro}>
            <CommonButton shadow   onClick={()=>navigate('/shop')}>{t("nft.upgradation")}</CommonButton>
          </div>
        }
        { pathname==="shop"&&
          <>
            <div className={styles.intro}>
              <div className={styles.coin}> {t("nft.price")}：<img src={gap}/><span>{info.price}</span></div>
            </div>
            <div>
              {!myHero ?
                // 0购买 1升级
                <CommonButton shadow onClick={()=>navigate('/shop/hero/buy',{state:{id,info,type:0}})}>{t("pool.buy")}</CommonButton>:
               
                <CommonButton shadow  disabled={ myHero.level>=info.level} onClick={()=>handleUpgrade()}>{t("pool.upgradation")}</CommonButton>
              }
            </div>
          </>
        }
      </CommonPage>
    );
  })
