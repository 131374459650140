/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 16:50:13
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-06-16 11:10:39
 * @FilePath: \weapp\wepay-h5\src\pages\cards\Record.js
 * @Description: 组件描述
 */
import styles from './Record.module.scss';
import React, {useRef, useState, memo} from 'react';

import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonNavBar from 'components/commonNavBar/CommonNavBar';
import moment from "moment";

import FilterPopup from "./FilterPopup";
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import {useTranslation} from "react-i18next";  // 引用
import { ReactComponent as IconWalletRecordRecharge } from "assets/images/icon/WalletRecordRecharge.svg";
import { ReactComponent as IconWalletRecordWithdraw } from "assets/images/icon/WalletRecordWithdraw.svg";
import { ReactComponent as IconWalletRecordGift } from "assets/images/icon/WalletRecordGift.svg";
import { ReactComponent as IconWalletRecordConsume } from "assets/images/icon/WalletRecordConsume.svg";
import { ReactComponent as IconFilter } from "assets/images/icon/Filter.svg";
import mineApi from '@/service/mineApi';

export default memo(function Record() {
  let {t} = useTranslation();

  const refList = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const getApiData = (page) => {
    let filterParam = {};
    if (filterData) {
      filterParam = {
        beginTime: filterData.timeEnd.start,
        endTime: filterData.timeEnd.end,
        maxAmount: filterData.inputMax,
        minAmount: filterData.inputMin,
        status: filterData.selectorInOut ? filterData.selectorInOut : '',
        type: filterData.selectorType || '',
        sort: filterData.selectorSort || '',
        coinName: filterData.selectorAssets || '',
      }
    }
    return mineApi.balanceHistory({
      pageNo: page,
      pageSize: 20,
      ...filterParam
    });
  }

  // 钱包记录类型图标
  const getIcon = (type) => {
    switch (type) {
      case 0:
        return <IconWalletRecordRecharge className={styles.listIcon} />;
      case 1:
        return <IconWalletRecordWithdraw className={styles.listIcon} />;
      case 11:
        return <IconWalletRecordConsume className={styles.listIcon} />;
      case 12:
        return <IconWalletRecordConsume className={styles.listIcon} />;
      case 13:
        return <IconWalletRecordGift className={styles.listIcon} />;
      case 14:
        return <IconWalletRecordGift className={styles.listIcon} />;
      default:
        return <IconWalletRecordRecharge className={styles.listIcon} />;
    }
  }

  // 明细类型txt
  const typeTxtFun = (type, item) => {
    let typeTxt
    switch (type) {
      case 0:
        typeTxt = t('lbl_recharge')
        break;
      case 1:
        if(item.status === 0) {
          typeTxt = t('lbl_withdraw_ing')
        }
        else if(item.status === 1) {
          typeTxt = t('lbl_withdraw_success')
        }
        else if(item.status === 2) {
          typeTxt = t('lbl_withdraw_fail')
        }
        break;
      case 11:
        typeTxt = t('lbl_buy')
        break;
      case 12:
        typeTxt = t('lbl_upgrade')
        break;
      case 13:
        typeTxt = t('lbl_pool_income')
        break;
      case 14:
        typeTxt = t('lbl_battlefield_income')
        break;
      case 15:
        if (item.tokenName === 'BSC_CAP') {
          typeTxt = t('lbl_airdrop_income')
        } else {
          typeTxt = t('lbl_recharge')
        }
        break;
      case 16:
        typeTxt = t('lbl_compote_income')
        break;
      case 17:
        typeTxt = t('lbl_community_income')
        break;
      case 19:
        typeTxt = t('lbl_peer')
        break;
      case 20:
        typeTxt = t('lbl_share1')
        break;
      case 21:
        typeTxt = t('lbl_share2')
        break;
      case 22:
        typeTxt = t('lbl_share3')
        break;

      default:
        break;
    }
    return typeTxt
  }

  const renderListItem = (item, index) => {

    return (
      <div className={`${styles.listItem} ${item.amount > 0 ? styles.curItem : styles.item}`}>
        {getIcon(item.type)}
        <div className={styles.listContent}>
          <div className={styles.itemTitle}>
            <span className={styles.itemLabel}>{typeTxtFun(item.type, item)}</span>
            <div className={`${styles.itemValue} ${item.amount < 0 ? styles.negative : ''}`}>
              {item.amount > 0 ? '+' + item.amount : item.amount} {item.blockCoinName}
            </div>
          </div>
          <div className={styles.ItemFooter}>
            {moment(item.createTime).format('HH:mm DD-MM-YYYY')}
            {
              item.type === 1 && (
                // 手续费
                <span className={`${styles.fee} ${item.amount < 0 ? styles.negative : ''}`}>{t('lbl_withdraw_fee')}&nbsp;-{item.fee} {item.blockCoinName}</span>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  // 标题栏右侧筛选按钮
  const renderFilterBtn = () => {
    return (
      <IconFilter className={styles.filterBtn} onClick={() => setShowFilter(true)} />
    )
  }

  return (
    <CommonPage title={t('lbl_wallet_record')} flex className={styles.record} header={<CommonNavBar right={renderFilterBtn()}>{t('lbl_wallet_record')}</CommonNavBar>}>
      <div className={styles.body}>
        <div className={styles.listBox}>
          <CommonPageScrollList
            ref={refList}
            renderListItem={renderListItem}
            getApiData={getApiData}>
          </CommonPageScrollList>
        </div>
      </div>

      <FilterPopup
        visible={showFilter} onCancel={() => setShowFilter(false)}
        onConfirm={(filter) => {
          setFilterData(filter);
          refList?.current.refreshApiData();
          setShowFilter(false)
        }}
      ></FilterPopup>
  </CommonPage>
  );
})
