/**
 * 公共弹窗组件 (主要用于业务类或者通用复杂内容弹窗)
 * @param {boolean} visible 是否显示弹窗
 * @param {String} title 弹窗标题
 * @param {String} content 弹窗内容
 * @param {Function} onClose 弹窗关闭回调
 * rest 其他参数
 */
import React from 'react';
import { Modal } from 'antd-mobile';
import './CommonModal.module.scss';

const CommonModal = (props) => {
  const { visible, header, title, content, onClose, ...rest } = props;

  return (
    <Modal
      //title={title}
      title={<div className='header'>{title}</div>}
      visible={visible}
      content={content}
      className='common-modal'
      onClose={() => {
        onClose && onClose();
      }}
      {...rest}
    >
    </Modal>
  )
}

export default CommonModal;
