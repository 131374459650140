import React, {useEffect, useState} from 'react';
import styles from "./WalletInfoPopup.module.scss";
import CommonPopup from "../../commonPopup/CommonPopup";
import {useAccount, useBalance, useDisconnect} from "wagmi";
import imageEth from 'assets/images/coin/eth.png';
import imageUsdt from 'assets/images/coin/usdt.png';
import imageBNB from 'assets/images/coin/bnb.png';
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import toast from "react-hot-toast";
import IconExit from "../../icon/IconExit";
import { ReactComponent as IconCopy } from '@icon/Copy.svg';
import useUserHooks from "hooks/useUserHooks";
import {usdtChainAddrConfig} from "../../../utils/walletConfig";
import {useTranslation} from "react-i18next";
import { formatWalletAddress } from 'utils/utils';

const WalletInfoPopup = (props) => {

    const {visible, onCancel, onSwitch} = props;

    const [showVisibleInner, setShowVisibleInner] = useState(false);
    let {t} = useTranslation();

    useEffect(() => {
        if (visible) setShowVisibleInner(true);
    }, [visible]);

    let {chain} = useAccount();
    const {address} = useAccount();
    const {userLogOut} = useUserHooks();

    let usdtAddrConfig = usdtChainAddrConfig;

    //每个链usdt地址需要配置下
    const {data: ethBalance} = useBalance({address: address})
    const {data: usdtBalance} = useBalance({
        address: address,
        token: usdtAddrConfig[chain?.network]
    })


    const CoinBalance = (props) => {

        const coinData = {
            'ETH': imageEth,
            'USDT': imageUsdt,
            'BNB': imageBNB,
            'tBNB': imageBNB
        }

        return (
            <div className={styles.balance}>
                <div className={styles.coinItem}>
                    <img className={styles.coin} src={coinData[ethBalance?.symbol]}></img>
                    <div
                        className={styles.title}>{parseFloat(ethBalance?.formatted).toFixed(2) + ' ' + ethBalance?.symbol}</div>
                </div>
                <div className={styles.coinItem}>
                    <img className={styles.coin} src={coinData['USDT']}></img>
                    <div
                        className={styles.title}>{parseFloat(usdtBalance?.formatted).toFixed(2) + ' ' + usdtBalance?.symbol}</div>
                </div>
            </div>
        )
    }

    const ButtonItem = (props) => {
        const {title, image, onClick} = props;
        return (
            <div className={styles.buttonItem} onClick={onClick}>
                {image}
                <div>{title}</div>
            </div>
        );
    }

    const copySuccess = () => {
        toast.success(t('msg_copy_success'));
    }

    return (
        <CommonPopup visible={showVisibleInner} getVisible={() => {
            setShowVisibleInner(false);
            onCancel && onCancel();
        }}>
            <div className={styles.wallets}>

                <div className={styles.address}>
                    <div>{formatWalletAddress(address)}</div>
                </div>

                <CoinBalance/>

                <div className={styles.bottom}>
                    {/* <ButtonItem image={<img src={imageEth} alt=""/>} title={chain?.name} onClick={() => {
                        setShowVisibleInner(false);
                        onSwitch && onSwitch();
                    }}/> */}

                    {
                        address &&
                        <CopyToClipboard text={address} onCopy={() => copySuccess()}>
                            <ButtonItem image={<IconCopy/>} title={t('lbl_copy_address')} onClick={() => {
                            }}/>
                        </CopyToClipboard>
                    }

                    <ButtonItem image={<IconExit/>} title={t('lbl_disconnect')} onClick={() => {
                        userLogOut();
                        onCancel && onCancel();
                        setShowVisibleInner(false)
                    }}/>

                </div>
            </div>
        </CommonPopup>
    );
};

export default WalletInfoPopup;