import React, {useEffect, useState} from 'react';
import styles from './CommonSelector.module.scss';
import './CommonSelector.scss';
import {Selector} from "antd-mobile";

const CommonSelector = (props) => {

    //自定义组件时自己的属性不要传给antd-mobile的组件，否则会报错
    const {...rest} = props;

    return (<Selector  {...rest} showCheckMark={false} className={'custom-selector'}>
    </Selector>);
};

export default CommonSelector;