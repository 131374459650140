import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {InfiniteScroll, List,} from "antd-mobile";
import {mockRequest} from "./mock";
import ResultPage from "./ResultPage";
import PropTypes from "prop-types";
import styles from './CommonPageScrollList.module.scss';

const CommonPageScrollList = forwardRef((props, ref) => {

    const {renderListItem, getApiData, extraClassName} = props;

    const [data, setData] = useState([]); // 列表数据
    const [page, setPage] = useState(1); // 当前页数
    const [total, setTotal] = useState(0); // 数据总数
    const [loading, setLoading] = useState(false); // 是否正在加载数据
    const [error, setError] = useState(false); // 是否出现异常错误
    const [refresh, setRefresh] = useState(false);

    const loadDataFromNet = async () => {
        return await getApiData ? getApiData(page) : mockRequest(page, 20);
    }

    useEffect(() => {
        fetchData();
    }, []);

    //暴露给父组件调用的方法
    useImperativeHandle(ref,
        () => ({
            refreshApiData
        }),
    );

    useEffect(() => {
        if (page === 1 && refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [refresh])

    const refreshApiData = () => {
        setData([]);
        setTotal(0)
        setPage(1);
        setRefresh(true);
    }

    // 获取数据
    const fetchData = async () => {
        // console.log('fetchData', page, pageSize, total, loading, error, data.length, data.length < total);
        try {
            setLoading(true); // 开始加载

            const response = await loadDataFromNet();
            console.log(response);
            //需要固定格式{ data:[],total:100,page:10}
            if (page === 1) {
                setData(response.data.list);
            } else {
                setData(data => [...data, ...response.data.list]); // 追加数据
            }
            setTotal(response.data.total); // 设置总数
            setPage(page + 1); // 增加页数

            setError(false); // 重置错误状态

            // console.log('fetchData', page, pageSize, total, loading, error, data.length < total);

        } catch (error) {
            setError(true); // 出现异常错误
            throw error; //异常需要抛出，组件需要更改成异常状态
        } finally {
            setLoading(false); // 加载完成
        }
    };


    const renderChild = (item, index) => {
        if (renderListItem) {
            return <List.Item key={index} arrow={false}>
                {
                    renderListItem(item, index)
                }
            </List.Item>
        }
        return (
            <List.Item key={index}
                       style={{height: '60px', fontsize: '32px'}}
                       arrow={false}
            >{item}</List.Item>
        )
    }

    return (
        <div>

            {
                data.length > 0 ? (
                        <>
                            <List className={styles.list}>
                                {data.map((item, index) => (
                                    renderChild(item, index)
                                ))}
                            </List>
                            <InfiniteScroll loadMore={fetchData} hasMore={data.length < total} threshold={320}>

                            </InfiniteScroll>
                        </>
                    )
                    :
                    (
                        <ResultPage error={error} loading={loading} extraClassName={extraClassName} onClick={() => {
                            refreshApiData();
                        }}/>
                    )

            }

        </div>
    );
});

CommonPageScrollList.prototype = {
    renderListItem: PropTypes.func,
    getApiData: PropTypes.func,
}

/**
 * 获取数据的方法
 * @param page 当前页数
 * @returns {Promise<{list: [], total: number}>}    返回数据格式, list: 数据列表, total: 数据总数
 */
CommonPageScrollList.prototype.getApiData = async (page) => {
    let response = await Promise.resolve({list: [], total: 0});
    return {list: response.list, total: response.total};
}

CommonPageScrollList.defaultProps = {}

export default CommonPageScrollList;