/* 首页API */
import {
  get,
  post,
  request
} from "./request";


const homeApi = {
  getHomeData: (params) => (post('/api/portal/home/index', params)),
  getKlineData: (params) => (post('/api/portal/home/kline', params)),
};

export default homeApi;
