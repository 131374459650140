import React from 'react';
import styles from './CommonFormItemLayout.module.scss';
import PropTypes from "prop-types";
import {Radio, Space} from 'antd-mobile'

const CommonFormItemLayout = (props) => {

    const {className, type2, type3} = props;

    return (
        <div className={`${styles.layout} ${type2 ? styles.layout2 : ''} ${type3 ? styles.layout3 : ''} ${className}`}>
            <div className={styles.title}>
                {props.title}
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

CommonFormItemLayout.prototype = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};

CommonFormItemLayout.defaultProps = {
    title: '标题',
    children: <div>内容</div>,
};


export default CommonFormItemLayout;